<template>
  <div class="no-user-selected">
    <div class="icon-wrapper">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370 270">
        <path
          data-name="Path 511"
          d="M348.368 24.054H20.204A11.582 11.582 0 008.636 35.622v35.313a11.582 11.582 0 0011.568 11.568h328.164a11.582 11.582 0 0011.568-11.568V35.622a11.582 11.582 0 00-11.568-11.568zm10.35 46.881a10.365 10.365 0 01-10.35 10.35H20.204a10.365 10.365 0 01-10.35-10.35V35.622a10.365 10.365 0 0110.35-10.35h328.164a10.365 10.365 0 0110.35 10.35z"
          fill="#3f3d56"
        />
        <path
          data-name="Path 512"
          d="M348.368 165.913H20.204a11.582 11.582 0 01-11.568-11.567v-35.313a11.582 11.582 0 0111.568-11.568h328.164a11.582 11.582 0 0111.568 11.568v35.313a11.582 11.582 0 01-11.568 11.567zm-328.164-57.23a10.362 10.362 0 00-10.35 10.35v35.313a10.362 10.362 0 0010.35 10.352h328.164a10.362 10.362 0 0010.35-10.35v-35.315a10.362 10.362 0 00-10.35-10.35z"
          fill="#ccc"
        />
        <path
          data-name="Path 513"
          d="M348.368 249.324H20.204a11.582 11.582 0 01-11.568-11.567v-35.313a11.582 11.582 0 0111.568-11.568h328.164a11.582 11.582 0 0111.568 11.568v35.313a11.582 11.582 0 01-11.568 11.567zM20.204 192.091a10.362 10.362 0 00-10.35 10.35v35.313a10.362 10.362 0 0010.35 10.35h328.164a10.362 10.362 0 0010.35-10.35v-35.31a10.362 10.362 0 00-10.35-10.353z"
          fill="#ccc"
        />
        <path
          data-name="Path 514"
          d="M64.546 73.413A20.134 20.134 0 1184.68 53.279a20.134 20.134 0 01-20.134 20.134z"
          fill="#6c63ff"
        />
        <path
          data-name="Path 515"
          d="M318.641 69.717H103.723a4.87 4.87 0 010-9.742H318.64a4.87 4.87 0 010 9.742z"
          fill="#ccc"
        />
        <path
          data-name="Path 516"
          d="M248.621 49.016H103.718a4.87 4.87 0 110-9.741h144.903a4.87 4.87 0 010 9.741z"
          fill="#ccc"
        />
        <path
          data-name="Path 517"
          d="M64.546 156.823a20.134 20.134 0 1120.134-20.134 20.134 20.134 0 01-20.134 20.134z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 518"
          d="M318.641 153.128H103.723a4.87 4.87 0 110-9.742H318.64a4.87 4.87 0 010 9.742z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 519"
          d="M248.621 132.427H103.718a4.87 4.87 0 110-9.741h144.903a4.87 4.87 0 010 9.741z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 520"
          d="M64.546 240.234A20.134 20.134 0 1184.68 220.1a20.134 20.134 0 01-20.134 20.134z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 521"
          d="M318.641 236.539H103.723a4.87 4.87 0 110-9.742H318.64a4.87 4.87 0 110 9.742z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 522"
          d="M248.621 215.838H103.718a4.87 4.87 0 110-9.741h144.903a4.87 4.87 0 010 9.741z"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 395"
          d="M63.518 60.36a2.303 2.303 0 01-1.387-.46l-.025-.019-5.219-3.991a2.32 2.32 0 112.822-3.682l3.38 2.593 7.988-10.42a2.318 2.318 0 013.25-.43l-.05.07.05-.07a2.321 2.321 0 01.43 3.25L65.36 59.453a2.32 2.32 0 01-1.844.901z"
          fill="#fff"
        />
      </svg>
    </div>
    <div class="text-wrapper">
      <!-- <div class="title">
        {{
          ftm__capitalize($t("user_access_management.no_user_selected.title"))
        }}
      </div> -->
      <div class="sub-title">
        {{
          ftm__capitalize(
            $t("user_access_management.no_user_selected.sub_title")
          )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [filtersMixin]
};
</script>
<style lang="scss" scoped>
.no-user-selected {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  .icon-wrapper {
    width: 75%;
    @include responsiveProperty(width, 55%, 50%, 50%);
  }
  .text-wrapper {
    .title {
      @include responsiveProperty(font-size, 20px, 23px, 26px);
    }
    .sub-title {
      @include normal-text;
    }
  }
}
</style>
