<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: addDevicesToPond.vue
Description: This file contians UI components used to add devices to pond. It is child component of pond tab of settings
-->
<template>
  <er-dialog
    width="28%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog('close')"
    :title="$t('Dev_add_dev')"
    class="add-devices-to-pond"
    custom-class="add-devices-dialog"
  >
    <el-form size="small" v-loading="loading">
      <el-form-item :label="$tc('Comn_pond', 1)">
        <el-col :span="24">
          <el-input
            :value="this.pond_details && this.pond_details.title"
            disabled
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item :label="$t('Comn_pond_guard')">
        <el-col :span="24">
          <er-select
            v-model="selectedPGs"
            :showSelectAll="true"
            class="select-devices"
            :reserve-keyword="true"
            value-key="code"
            collapse-tags
            multiple
            filterable
            :placeholder="$t('Comn_select')"
            :no-data-text="$t('Comn_no_pondguards')"
          >
            <el-option
              v-for="item in pond_guards"
              :key="item._id"
              :label="item.code"
              :value="item"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>
      <el-form-item :label="$t('Comn_pond_mother')">
        <el-col :span="24">
          <er-select
            v-model="selectedPMs"
            :showSelectAll="true"
            class="select-devices"
            value-key="code"
            :reserve-keyword="true"
            collapse-tags
            multiple
            filterable
            :placeholder="$t('Comn_select')"
            :no-data-text="$t('Comn_no_pondmothers')"
          >
            <el-option
              v-for="item in pond_mothers"
              :key="item._id"
              :label="item.code"
              :value="item"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('Comn_shrimptalk')">
        <el-col :span="24">
          <er-select
            class="select-devices"
            :showSelectAll="true"
            v-model="selectedSTs"
            value-key="code"
            :reserve-keyword="true"
            collapse-tags
            multiple
            filterable
            :placeholder="$t('Comn_select')"
            :no-data-text="$t('Comn_no_shrimptalks')"
          >
            <el-option
              v-for="item in shrimp_talks"
              :key="item._id"
              :label="item.code"
              :value="item"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="$tc('Comn_feed_blower', 1)"
        v-ftgm__feature-enabled="feedBlowerFeatureReq"
      >
        <el-col :span="24">
          <er-select
            class="select-devices"
            :showSelectAll="true"
            v-model="selectedFBs"
            value-key="code"
            :reserve-keyword="true"
            collapse-tags
            multiple
            filterable
            :placeholder="$t('Comn_select')"
            :no-data-text="$t('Comn_no_feedblowers')"
          >
            <el-option
              v-for="item in feed_blowers"
              :key="item._id"
              :label="item.code"
              :value="item"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>
      <el-form-item
        :label="$tc('Comn_shrimp_snap', 1)"
        v-ftgm__feature-enabled="shrimpSnapFeatureReq"
      >
        <el-col :span="24">
          <er-select
            class="select-devices"
            :showSelectAll="true"
            v-model="selectedSSs"
            value-key="code"
            :reserve-keyword="true"
            collapse-tags
            multiple
            filterable
            :placeholder="$t('Comn_select')"
            :no-data-text="$t('Comn_no_shrimpsnaps')"
          >
            <el-option
              v-for="item in shrimp_snaps"
              :key="item._id"
              :label="item.code"
              :value="item"
            ></el-option>
          </er-select>
        </el-col>
      </el-form-item>
    </el-form>

    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="submitDeviceDetails"
        :disabled="isReadOnly"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('close')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import { PM_MODES } from "@/constants/schedule";
export default {
  mixins: [errorHandlerMixin, featureToggleMixin],
  props: {
    pond_details: {
      default: {}
    },
    showDialog: {
      default: false
    }
  },
  data: function() {
    return {
      pond_guards: [],
      pond_mothers: [],
      shrimp_talks: [],
      feed_blowers: [],
      shrimp_snaps: [],
      selectedPMs: [],
      selectedSTs: [],
      selectedPGs: [],
      selectedFBs: [],
      selectedSSs: [],
      query: {
        get_all: true,
        include: ["title", "code"],
        un_assigned_only: true
      },
      loading: false
    };
  },
  async created() {
    await this.initComponent();
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    feedBlowerFeatureReq: function() {
      return {
        featureAllowedUsers: [
          "5e1897b5188b23258b34654d",
          "5e565a0cbc2c4dd8b5f6f041",
          "5e5cfb322c599d13d25b607e",
          "5e1897b5188b23258b346396"
        ],
        currUser: this.getUserProfile
      };
    },
    shrimpSnapFeatureReq: function() {
      return {
        featureAllowedUsers: [
          "5e1897b5188b23258b34654d",
          "5e565a0cbc2c4dd8b5f6f041",
          "5f1926e917a2492077983958",
          "5e5cfb322c599d13d25b607e",
          "5e1897b5188b23258b346396",
          "5e1897b5188b23258b3465c3",
          "5e1897b5188b23258b34637e",
          "5e1897b5188b23258b34638e",
          "5e565a0cbc2c4dd8b5f6f04c",
          "5ff6cf2016578b2c1c93c940",
          "61331ae3bc214f7f70e834ad"
        ],
        currUser: this.getUserProfile
      };
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    deviceToPayloadFunctionMap() {
      return {
        pond_mother: pmArg => {
          const pm = this.$lodash.cloneDeep(pmArg);
          return {
            pond_id: this.pond_details._id,
            status: "ACTIVE",
            managed_by:
              pm.managed_by === undefined ||
              pm.managed_by === PM_MODES.AUTOMATIC
                ? PM_MODES.SCHEDULE
                : pm.managed_by,
            title: pm.title || pm.code,
            pond_mother_id: pm._id
          };
        },
        feed_blower: (acc, fbArg) => {
          acc.data.feed_blower_ids.push(fbArg._id);
          return acc;
        },
        pond_guard: pgArg => {
          const pg = this.$lodash.cloneDeep(pgArg);
          pg.title = pg.title || pg.code;
          pg.status = "ACTIVE";
          pg.pond_id = this.pond_details._id;
          delete pg.settings;
          return pg;
        },
        shrimp_talk: stArg => {
          const st = this.$lodash.cloneDeep(stArg);
          return {
            title: st.title || st.code,
            status: "ACTIVE",
            pond_id: this.pond_details._id,
            shrimp_talk_id: st._id,
            settings: {
              feed_limit: st.settings.feed_limit || 0
            }
          };
        },
        shrimp_snap: ssArg => {
          return {
            pond_id: this.pond_details._id,
            shrimp_snap_id: ssArg._id,
            title: ssArg.title || ssArg.code
          };
        }
      };
    }
  },
  methods: {
    handleCloseDialog(event) {
      this.selectedSTs = [];
      this.selectedPMs = [];
      this.selectedPGs = [];
      this.selectedFBs = [];
      this.selectedSSs = [];
      this.$emit("close_dialog", event);
    },
    async initComponent() {
      this.loading = true;
      const pmQueryParams = {
        ...this.query,
        include: ["title", "code", "managed_by"]
      };
      const devicesConfig = {
        pond_mother: {
          action: "pondmother/fetchAllPondMothers",
          query: pmQueryParams,
          dataProperty: "pond_mothers",
          getter: "pondmother/getPondMothers",
          ftgm__isFeatureAvailable: true
        },
        shrimp_talk: {
          action: "shrimptalk/fetchAllShrimpTalks",
          query: this.query,
          dataProperty: "shrimp_talks",
          getter: "shrimptalk/getShrimpTalks",
          ftgm__isFeatureAvailable: true
        },
        pond_guard: {
          action: "pondguard/fetchAllPondGuards",
          query: this.query,
          dataProperty: "pond_guards",
          getter: "pondguard/getPondGuards",
          ftgm__isFeatureAvailable: true
        },
        feed_blower: {
          action: "feedblower/fetchAllFeedBlowers",
          query: this.query,
          dataProperty: "feed_blowers",
          getter: "feedblower/getFeedBlowers",
          ftgm__isFeatureAvailable: this.ftgm__isFeatureAvailable(
            this.feedBlowerFeatureReq
          )
        },
        shrimp_snap: {
          action: "shrimpsnap/fetchAll",
          getter: "shrimpsnap/getShrimpSnaps",
          dataProperty: "shrimp_snaps",
          query: this.query,
          ftgm__isFeatureAvailable: this.ftgm__isFeatureAvailable(
            this.shrimpSnapFeatureReq
          )
        }
      };
      const promiseGetAllDeviceData = [
        "pond_mother",
        "shrimp_snap",
        "shrimp_talk",
        "feed_blower",
        "pond_guard"
      ].reduce((acc, currDeviceName) => {
        const currDevice = devicesConfig[currDeviceName];
        if (currDevice.ftgm__isFeatureAvailable) {
          acc.push(
            this.$store
              .dispatch(currDevice.action, currDevice.query)
              .then(() => {
                this[currDevice.dataProperty] = this.$lodash.cloneDeep(
                  this.$store.getters[currDevice.getter]
                );
                this[currDevice.dataProperty].sort((a, b) =>
                  this.$commonUtils.alphaNumericComparator(a.code, b.code, 1)
                );
              })
              .catch(err => {
                this.ehm__errorMessages(err, true);
              })
          );
        }
        return acc;
      }, []);
      await Promise.all(promiseGetAllDeviceData);
      this.loading = false;
    },

    async submitDeviceDetails() {
      this.loading = true;
      const deviceToAddActionMap = {
        pond_mother: "pondmother/addPondMotherDetails",
        feed_blower: "feedblower/assignFeedBlowersToPond",
        shrimp_talk: "shrimptalk/addShrimpTalkDetails",
        pond_guard: "pondguard/addPondGuardDetails",
        shrimp_snap: "shrimpsnap/addToPond"
      };
      const devicesToSelectedDevicesMap = {
        pond_mother: this.selectedPMs,
        shrimp_talk: this.selectedSTs,
        pond_guard: this.selectedPGs,
        feed_blower: this.selectedFBs,
        shrimp_snap: this.selectedSSs
      };
      try {
        if (
          [
            this.selectedPMs,
            this.selectedSTs,
            this.selectedPGs,
            this.selectedFBs,
            this.selectedSSs
          ].every(x => x.length === 0)
        ) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("DL_no_device_selected")
              }
            ]
          };
        }
        const payloadArr = [
          {
            device: "pond_mother",
            ftgm__isFeatureAvailable: true,
            arrMethod: "map"
          },
          {
            device: "shrimp_talk",
            ftgm__isFeatureAvailable: true,
            arrMethod: "map"
          },
          {
            device: "pond_guard",
            ftgm__isFeatureAvailable: true,
            arrMethod: "map"
          },
          {
            device: "shrimp_snap",
            ftgm__isFeatureAvailable: this.ftgm__isFeatureAvailable(
              this.shrimpSnapFeatureReq
            ),
            arrMethod: "map"
          },
          {
            device: "feed_blower",
            ftgm__isFeatureAvailable: this.ftgm__isFeatureAvailable(
              this.feedBlowerFeatureReq
            ),
            arrMethod: "reduce",
            initObj: {
              data: { feed_blower_ids: [] },
              pond_id: this.pond_details._id
            }
          }
        ].reduce((acc, deviceConfig) => {
          if (
            deviceConfig.ftgm__isFeatureAvailable &&
            devicesToSelectedDevicesMap[deviceConfig.device].length > 0
          ) {
            acc.push([
              deviceToAddActionMap[deviceConfig.device],
              [][deviceConfig.arrMethod].apply(
                devicesToSelectedDevicesMap[deviceConfig.device],
                deviceConfig.arrMethod === "reduce"
                  ? [
                      this.deviceToPayloadFunctionMap[deviceConfig.device],
                      deviceConfig.initObj
                    ]
                  : [this.deviceToPayloadFunctionMap[deviceConfig.device]]
              )
            ]);
          }
          return acc;
        }, []);
        await payloadArr.reduce((acc, payloadConfig) => {
          return acc.then(() =>
            this.$store.dispatch(payloadConfig[0], payloadConfig[1])
          );
        }, Promise.resolve());
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Comn_add_devices_user"),
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        const errors = [];
        if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.message) {
          errors.push({ message: this.$t("Something_went_wrong") });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.handleCloseDialog("deviceAddition");
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.add-devices-to-pond {
  .el-dialog__body {
    padding: 15px 15px;
  }
  .el-form {
    .el-form-item__content {
      .select-devices {
        width: 200px;
      }
    }
  }
}
.add-devices-dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 200px, 200px, 200px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(width, 90px, 135px, 200px);
  }
}
</style>
