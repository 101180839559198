<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: units.vue
Description: This file is the bases componet of user units in settings page and contains a form to change user preferrences
-->
<template>
  <el-row class="units-container">
    <el-col :span="12">
      <div class="header_pre_container">
        <p class="header_container">{{ this.$t("Comn_units") }}</p>
        <span class="hr_line"></span>
      </div>

      <el-form
        v-loading="loading"
        :inline="true"
        ref="userCreate"
        :model="units"
        size="small"
        label-position="left"
        :hide-required-asterisk="true"
      >
        <el-form-item
          v-for="(value, key) in mapUnitToObjKey"
          :label="$t(formUnitsLabels[key])"
          :key="key"
        >
          <er-select v-if="units" v-model="units[key]" :value-key="mapUnitToObjKey[key]" @change="handelChangeUnits(formUnitsLabels[key])">
            <el-option
              v-for="unit in formUnits[key]"
              :key="unit.label"
              :value="unit"
              :label="ftm__capitalize(unit.label)"
            >
            </el-option>
          </er-select>
        </el-form-item>
        <el-row class="btn-container">
          <el-col :span="12">
            <el-row type="flex">
              <er-button
                size="mini"
                btnType="save"
                :showLabel="true"
                :showIcon="true"
                :loading="loading"
                :disabled="isReadOnly"
                @click="updateUnits"
              ></er-button>
              <er-button
                size="mini"
                btnType="cancel"
                :showLabel="true"
                @click="handleCancel"
                :disabled="isReadOnly"
              ></er-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import userService from "@/services/userService";
// import countryToDefaultUnits from "@/constants/units";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  name: "Units",
  mixins: [errorHandlerMixin, filtersMixin],
  props: ['tabData'],
  data: function () {
    return {
      selectedFields: [],
      loading: false,
      units: this.updateDefaultUnit, // countryToDefaultUnits.other,
      mapUnitToObjKey: {
        date_format: "value",
        time_format: "value",
        biomass_units: "value",
        // feed_units: "value",
        biomass_per_area_units: "key",
        pond_area_units: "value",
        device_name: "value",
        feed_gap: "value",
        default_location_id: "value",
        default_dashboard_tab: "value"
      },
      mapUnitToPayloadValKey: {
        date_format: "value",
        time_format: "value",
        biomass_units: "value",
        feed_units: "value",
        biomass_per_area_units: ["biomass_units", "pond_area_units"],
        device_name: "value",
        pond_area_units: "value",
        feed_gap: "value",
        default_location_id: "value",
        default_dashboard_tab: "value"
      },
      formUnitsLabels: {
        date_format: "Comn_date",
        time_format: "Comn_time",
        do_units: "Comn_do",
        biomass_units: "Comn_biomass",
        abw_and_growth_units: "ABW_abw",
        feed_units: "Comn_feed",
        biomass_per_area_units: "Comn_biomass_pond_area",
        device_name: "device_name",
        pond_area_units: "Ponds_water_spread_area",
        feed_gap: "Comn_feed_gap",
        default_location_id: "Comn_locations",
        default_dashboard_tab: "Comn_default_tab",
      },
      formUnits: {
        date_format: [
          {
            label: "DD MMM, YY",
            value: "dd MMM, yy"
          },
          {
            label: "DD MMM",
            value: "dd MMM"
          },
          {
            label: "DD/MM/YYYY",
            value: "dd/MM/yyyy"
          }
        ],
        time_format: [
          { label: "HH:MM", value: "HH:mm" },
          { label: "HH:MM AM/PM", value: "hh:mm a" }
        ],
        do_units: [{ label: "mg/l", value: "mg/l" }],
        biomass_units: [
          {
            label: "kg",
            value: "kg"
          },
          {
            label: "lb",
            value: "lb"
          }
        ],
        abw_and_growth_units: [
          {
            label: "g",
            value: "g"
          }
        ],
        feed_units: [
          { label: "kg", value: "kg" },
          { label: "lb", value: "lb" }
        ],
        biomass_per_area_units: [
          {
            label: "kg/ac",
            key: "kgac",
            biomass_units: "kg",
            pond_area_units: "ac"
          },
          {
            label: "kg/ha",
            key: "kgha",
            biomass_units: "kg",
            pond_area_units: "ha"
          },
          {
            label: "lb/ac",
            key: "lbac",
            biomass_units: "lb",
            pond_area_units: "ac"
          },
          {
            label: "lb/ha",
            key: "lbha",
            biomass_units: "lb",
            pond_area_units: "ha"
          }
        ],
        pond_area_units: [
          { label: "acres", value: "ac" },
          { label: "hectares", value: "ha" }
        ],
        device_name: [
          {
            label: this.$t("Comn_alias_name"),
            value: "alias_name"
          },
          {
            label: this.$t("Comn_Device_ID"),
            value: "code"
          }
        ],
        feed_gap: [
          { label: this.$t("show"), value: "SHOW" },
          { label: this.$t("hide"), value: "HIDE" }
        ],
        default_location_id: [],
        default_dashboard_tab: [{ label: this.$t("Comn_dashboard"), value: "dashboardTab" },
                              { label: `${this.$t("Comn_ponds")} ${this.$t("Comn_info")}`, value: "pondInfoTab" },
                              { label: `${this.$t("Comn_ponds")} ${this.$t("Comn_alerts")}`, value: "pondAlertsTab" }]
      }
    };
  },
  mounted() {
    this.units = this.updateDefaultUnit;
    this.initComponent();
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation",
      getUserLocations: "getUserLocations",
      getPreferredUnits: "getPreferredUnits"
    }),
    updateDefaultUnit() {
        return this.getPreferredUnits;
    },
    getUnitsObj: function () {
      const castArrToObj = (arr, key) => {
        return arr.reduce((acc, item) => {
          acc[item[key]] = item;
          return acc;
        }, {});
      };
      return Object.keys(this.mapUnitToObjKey).reduce((acc, key) => {
        acc[key] = castArrToObj(this.formUnits[key], this.mapUnitToObjKey[key]);
        return acc;
      }, {});
    }
  },
  methods: {
    ...mapActions("user", {
      fetchUserLocations: "fetchUserLocations",
      fetchUserProfile: "fetchUserProfile",
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelChangeUnits(type) {
      const formUnitsLabels = {
        Comn_date: "Date",
        Comn_time: "Time",
        Comn_biomass: "Biomass",
        Comn_biomass_pond_area: "Biomass / Pond Area",
        device_name: "Device Name",
        Ponds_water_spread_area: "Pond Area",
        Comn_feed_gap: "Feed Gap",
        Comn_locations: "Locations",
        Comn_default_tab: "Default Dashboard Tab",
      };
      console.log("type", formUnitsLabels[type], this.selectedFields, this.selectedFields.includes(formUnitsLabels[type]));
      if (!this.selectedFields.includes(formUnitsLabels[type])) {
          this.selectedFields.push(formUnitsLabels[type]);
      }
    },
    getLocations() {
      this.formUnits.default_location_id = this.getUserLocations.map((loc) => {
        return {
          label: this.ftm__capitalize(loc.name),
          value: loc._id
        };
      });
    },
    initComponent() {
      this.getLocations();
      this.units = Object.keys(this.mapUnitToObjKey).reduce((acc, key) => {
        if (key === "biomass_per_area_units") {
          const valKey = Object.values(
            this.getPreferredUnits[key] || this.units[key]
          )
            .map((x) => x.toLowerCase())
            .join("");
          acc[key] = this.getUnitsObj[key][valKey];
          return acc;
        }
        if (key === "default_location_id") {
          acc[key] = {
            label: this.getUserLocations[0]?.name ? this.ftm__capitalize(this.getUserLocations[0].name) : '',
            value:
              this.getPreferredUnits.default_location_id ||
              this.getUserLocations[0]?._id || ''
          };
          return acc;
        }
        acc[key] =
          this.getUnitsObj[key][this.getPreferredUnits[key] || this.units[key]];
        return acc;
      }, this.$lodash.cloneDeep(this.units));
    },

    async updateUnits() {
      this.loading = true;
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        const payload = Object.keys(this.mapUnitToPayloadValKey).reduce(
          (acc, key) => {
            if (Array.isArray(this.mapUnitToPayloadValKey[key])) {
              acc[key] = this.mapUnitToPayloadValKey[key].reduce(
                (nestAcc, nestKey) => {
                  nestAcc[nestKey] = this.units[key][nestKey];
                  return nestAcc;
                },
                {}
              );
              return acc;
            }
            acc[key] =
              this.units[key][this.mapUnitToPayloadValKey[key]] ||
              this.getPreferredUnits[key];
            return acc;
          },
          {}
        );
        await userService.updateGlobalSettings({
          ...this.getPreferredUnits,
          ...payload
        });
        await this.fetchUserProfile();
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Units_upd_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Settings - Preference - Save" });
        this.selectedFields.map(val => {
          this.mixPanelEventGenerator({ eventName: "Settings - Preference - " + val + " - Changed" });
        })
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
        this.selectedFields = [];
      }
    },
    handleCancel() {
      this.units = Object.keys(this.mapUnitToObjKey).reduce((acc, key) => {
        if (key === "biomass_per_area_units") {
          const valKey = Object.values(
            this.getPreferredUnits[key] || this.units[key]
          )
            .map((x) => x.toLowerCase())
            .join("");
          acc[key] = this.getUnitsObj[key][valKey];
          return acc;
        }
        if (key === "default_location_id") {
          acc[key] = {
            label: this.ftm__capitalize(this.getUserLocations[0].name),
            value:
              this.getPreferredUnits.default_location_id ||
              this.getUserLocations[0]._id
          };
          return acc;
        }
        acc[key] =
          this.getUnitsObj[key][this.getPreferredUnits[key] || this.units[key]];
        return acc;
      }, {});
    }
  }
};
</script>

<style lang="scss">
.units-container {
  padding: 0px 0px 0px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .header_pre_container {
    @include responsiveProperty(padding-bottom, 5px, 10px, 10px);
  }

  .header_container {
    // padding: 5px;
    @include responsiveProperty(padding-top, 7px, 10px, 10px);
    color: #0a2463;
    line-height: 30px;
    height: 30px;
    font-weight: 700;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    border-bottom: 1px solid #dddddd;
    @include responsiveProperty(width, 170vh, 170vh, 190vh);
  }

  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-around;
  }
  .el-form {
    .er-select-dropdown .el-select-dropdown__item {
      text-transform: initial;
    }
    .er-select .el-input__inner {
      text-transform: initial;
    }

    .el-form-item__label {
      margin-right: 8px;
      @include responsiveProperty(min-width, 150px, 177px, 215px);

      color: #000000;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      font-weight: 700;
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
  }
}
.er-select-dropdown .el-select-dropdown__item {
  text-transform: initial;
}
</style>
