<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedTemplate.vue
Description: This file contains UI components used in Feed Template
-->
<template>
  <div class="feedTemplate">
    <el-alert
      ref="error_alert"
      v-if="ehm__unhandledErrorMessage !== ''"
      :title="ehm__unhandledErrorMessage"
      type="error"
      @close="ehm__clearErrorMessages"
    ></el-alert>
    <el-row style="display: flex; flex-direction: row">
      <el-col :span="10" class="feed-temp-l-section">
        <!-- <div style="overflow-x:auto;"> -->
        <el-row style="padding-left: 10px">
          <el-col :span="11" class="create-new-set">
            <img
              class="create-new-set-image"
              src="@/assets/settings/calendar.svg"
            />
            <div>{{ $t("PM_create_new_set") }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-form :model="feedTemplate" size="mini" class="feed-table">
            <div style="display: flex; flex-direction: column">
              <el-form-item :label="$t('Comn_alias_name')" class="alias-name">
                <el-input
                  maxlength="13"
                  v-model="feedTemplate.title"
                  :placeholder="$t('Comn_name')"
                />
              </el-form-item>
              <table
                class="
                  el-table
                  el-table--fit
                  el-table--enable-row-hover
                  el-table--enable-row-transition
                "
              >
                <thead>
                  <th class="row-header-column-name is-leaf">
                    <er-text-tag
                      :text-to-scroll="`${tableRowsNames.particulars}`"
                      :threshold-characters="12"
                      >{{ tableRowsNames.particulars }}</er-text-tag
                    >
                  </th>
                  <th class="is-leaf">{{ tableRowsNames.feed_percentage }}</th>
                  <th class="is-leaf">{{ tableRowsNames.s_time }}</th>
                  <th class="is-leaf">{{ tableRowsNames.e_time }}</th>
                </thead>

                <tr
                  v-for="(slot, index) in feedTemplate.timeSlots"
                  :key="index"
                >
                  <th>
                    <span
                      style="
                        color: #0a2463;
                        font-weight: normal;
                        padding-left: 4px;
                      "
                      >{{ `S ${index + 1}` }}</span
                    >
                  </th>
                  <td class="feed-percent-container">
                    <el-form-item>
                      <el-input
                        type="number"
                        :controls="false"
                        :min="0"
                        :max="100"
                        v-model="slot.feed_percentage"
                        @change="handleFeedChange($event, index)"
                      ></el-input>
                    </el-form-item>
                  </td>
                  <td class="time-container">
                    <el-form-item>
                      <!-- :error="feedTemplate.timeSlots[i-1].errorMessage" -->
                      <el-time-picker
                        size="mini"
                        v-model="slot.s_time"
                        :picker-options="picker_options"
                        value-format="HH:mm"
                        format="HH:mm"
                        :clearable="false"
                        @change="handleTimeChange($event, index)"
                      ></el-time-picker>
                    </el-form-item>
                  </td>
                  <td class="time-container">
                    <el-form-item>
                      <!-- :error="feedTemplate.timeSlots[i-1].errorMessage" -->
                      <el-time-picker
                        size="mini"
                        v-model="slot.e_time"
                        :picker-options="picker_options"
                        value-format="HH:mm"
                        format="HH:mm"
                        :clearable="false"
                        @change="handleTimeChange($event, index)"
                      ></el-time-picker>
                    </el-form-item>
                  </td>
                </tr>
              </table>

              <div class="el-table__append-wrapper">
                <div class="el-table__append-wrapper">
                  <el-row type="flex" justify="end" class="left-btn-container">
                    <er-button
                      btnType="save"
                      size="mini"
                      :showLabel="true"
                      :showIcon="true"
                      :disabled="isReadOnly"
                      :loading="loading"
                      @click="submitFeedTemplateDetails"
                    >
                      <slot>
                        <p style="color: white">
                          {{
                            isEditingExistingFT
                              ? `${$t("Comn_update") + " " + $t("set")}`
                              : `${$t("PM_create_new_set")}`
                          }}
                        </p>
                      </slot>
                    </er-button>

                    <er-button
                      btnType="cancel"
                      size="mini"
                      :showLabel="true"
                      :disabled="isReadOnly"
                      @click="handleChangeFT('newSet')"
                    ></er-button>
                  </el-row>
                </div>
              </div>
            </div>
          </el-form>
        </el-row>

        <!-- </div> -->
      </el-col>
      <el-col
        :span="14"
        class="feed-temp-r-section"
        v-loading="loading"
        element-loading-background="white"
      >
        <el-row
          v-if="Object.keys(existingFTs).length > 0"
          style="padding-left: 22px"
        >
          <el-row
            v-for="value in existingFTs"
            :key="value.key"
            style="margin-bottom: 40px"
            class="set-data-container"
          >
            <el-row
              style="display: flex; align-items: center; margin-bottom: 10px"
            >
              <el-col class="set-title" style>{{ value.title }}</el-col>
              <el-col style="display: flex; justify-content: flex-end">
                <er-button
                  size="mini"
                  btnType="editSolid"
                  :disabled="isReadOnly"
                  :showLabel="true"
                  :showIcon="true"
                  @click="handleChangeFT(value._id)"
                ></er-button>
                <er-button
                  size="mini"
                  btnType="deleteForever"
                  :showLabel="true"
                  :showIcon="true"
                  @click="deleteTemplate(value._id)"
                  :disabled="isReadOnly"
                ></er-button>
              </el-col>
            </el-row>
            <el-row class="sets-data">
              <table
                class="
                  el-table
                  el-table--fit
                  el-table--enable-row-hover
                  el-table--enable-row-transition
                "
              >
                <thead>
                  <tr class="el-table__row">
                    <th class="row-header-column-name is-leaf">
                      {{ tableRowsNames.particulars }}
                    </th>
                    <th
                      class="is-leaf"
                      v-for="i in value.timeSlots.length"
                      :key="i"
                    >
                      <span>{{ `S${i}` }}</span>
                    </th>
                  </tr>
                </thead>
                <tr class="el-table__row">
                  <td rowspan="1" colspan="1" class="row-header">
                    {{ tableRowsNames.feed_percentage }}
                  </td>
                  <td
                    rowspan="1"
                    colspan="1"
                    v-for="i in value.timeSlots.length"
                    :key="i"
                  >
                    {{ value.timeSlots[i - 1]["feed_percentage"] }} %
                  </td>
                </tr>
                <tr class="el-table__row">
                  <td class="row-header">{{ tableRowsNames.s_time }}</td>
                  <td v-for="i in value.timeSlots.length" :key="i">
                    {{ value.timeSlots[i - 1]["s_time"] }}
                  </td>
                </tr>
                <tr class="el-table__row">
                  <td class="row-header">{{ tableRowsNames.e_time }}</td>
                  <td v-for="i in value.timeSlots.length" :key="i">
                    {{ value.timeSlots[i - 1]["e_time"] }}
                  </td>
                </tr>
              </table>
            </el-row>
          </el-row>
        </el-row>

        <el-row v-else style="display: flex; justify-content: center">
          <el-row
            v-if="!tempLoading"
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <img src="@/assets/settings/inbox.svg" />
            <p>{{ $t("Comn_no_sets_available") }}</p>
          </el-row>
        </el-row>
        <!-- </el-scrollbar> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FeedTemplate from "@/model/feedtemplate";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FeedTemplate",
  mixins: [errorHandlerMixin, datesHandlerMixin],
  props: {
    tabData: {
      default: {}
    },
    location: {
      default: {}
    },
    tempLoading: {
      default: true
    }
  },
  data: function () {
    return {
      feedTemplate: FeedTemplate.getSampleFT(),
      isEditingExistingFT: false,
      editFeedTempId: "",
      // selectedFT: "",
      picker_options: {
        format: "HH:mm"
      },
      errorMessage: "",
      loading: true,
      feedpercentagearray: [],
      timeslotsarray: []
    };
  },
  watch: {
    selectedFTId: function (newSetId, oldSetId) {
      if (newSetId === "newSet") {
        if (this.isEditingExistingFT) {
          this.isEditingExistingFT = false;
          this.initNewFT();
        }
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    existingFTs() {
      return this.$store.getters["schedules/getMapftIdFT"];
    },
    scrollBarWrapClass() {
      if (Object.values(this.existingFTs).length > 0) {
        return "feed-template__warp";
      } else {
        return "feed-template__warp-no-text";
      }
    },
    selectedFTId: {
      get() {
        const templateId =
          this.$store.getters["schedules/getCurrentFeedTemplateId"];
        return templateId;
      },
      async set(templateId) {
        this.loading = true;
        await this.$store.dispatch(
          "schedules/setCurrentFeedTemplateId",
          templateId
        );
        this.loading = false;
      }
    },
    tableRowsNames() {
      return {
        particulars: this.$t("Comn_schedules"),
        feed_percentage: this.$t("feed_ratio_%"),
        s_time: this.$t("PM_start_time"),
        e_time: this.$t("PM_end_time")
      };
    }
  },
  async mounted() {
    this.loading = true;

    if (Object.keys(this.existingFTs).length === 0) {
      await this.initNewFT();
      this.loading = false;
    } else {
      await this.initNewFT();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    initNewFT() {
      this.feedTemplate = FeedTemplate.getSampleFT();
      this.feedTemplate.location_id = this.location._id;
    },
    initComponent() {
      this.loading = true;
      this.$emit("initComponentData");
      this.loading = false;
    },
    handleChangeFT(feedTemplateId) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.loading = true;
        if (feedTemplateId === "newSet") {
          this.isEditingExistingFT = false;
          this.presentEditTemplate = "newSet";
          this.initNewFT();
        } else {
          this.editFeedTempId = feedTemplateId;
          this.isEditingExistingFT = true;
          this.feedTemplate = this.$lodash.cloneDeep(
            this.existingFTs[feedTemplateId]
          );
        }
        this.loading = false;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    handleFeedChange(feedValue, indexTimeSlot) {
      const currTS = this.feedTemplate.timeSlots[indexTimeSlot];
      if (feedValue === "") {
        currTS.feed_percentage = +feedValue;
      } else if (feedValue < 0) {
        currTS.feed_percentage = 0;
      } else if (feedValue > 100) {
        currTS.feed_percentage = 100;
      } else {
        currTS.feed_percentage = +feedValue;
      }
    },
    handleTimeChange(timeSelected, indexTimeSlot) {
      const currTS = this.feedTemplate.timeSlots[indexTimeSlot];
      currTS.s_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.s_time);
      console.log(this.$commonUtils.timeStrHHmmVal(currTS.s_time));
      currTS.e_time_secs = this.$commonUtils.timeStrHHmmVal(currTS.e_time);
    },
    setTimeRangeError(indexTimeSlot) {
      return `${this.$t(
        "given_schedule_overlapping_with_the_schedule"
      )} ${indexTimeSlot}`;
    },
    isExisting() {
      const { title: newTitle } = this.feedTemplate;

      if (this.isEditingExistingFT) {
        const currnetEdit = this.$lodash.cloneDeep(
          this.existingFTs[this.editFeedTempId]
        );
        const { title: editExistingTitle } = currnetEdit;

        const updateItem = Object.values(this.existingFTs).find(
          ({ title }) => title === newTitle
        );

        if (!updateItem) {
          return false;
        } else {
          if (updateItem.title === editExistingTitle) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return !!Object.values(this.existingFTs).find(
          ({ title }) => title === newTitle
        );
      }
    },
    submitFeedTemplateDetails: async function () {
      try {
        this.$gblUAMCanUserEdit(this.tabData);

        if (this.isExisting()) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("SET_name_already_exists")
              }
            ]
          };
        } else {
          const errorObj = this.feedTemplate.validate();
          if (errorObj.length > 0) {
            console.log(errorObj);
            this.formatErrors(errorObj);
            throw {
              type: "FAIL_TO_SAVE",
              errors: errorObj
            };
          }
          this.calculateDuration();
          this.loading = true;
          const payload = this.$lodash.cloneDeep(this.feedTemplate);
          if (payload.ponds.length > 0) {
            payload.ponds = payload.ponds.map(({ _id }) => _id);
          }
          const isEdit = this.isEditingExistingFT;
          await this.$store.dispatch(
            (this.isEditingExistingFT && "schedules/updateFeedTemplate") ||
              "schedules/createFeedTemplate",
            payload
          );

          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: isEdit
              ? this.$t("Set_updated_successfully")
              : this.$t("Set_created_successfully"),
            duration: 5000,
            type: "success"
          });
          isEdit ? this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Schedules One Time Settings - Update set" })
            : this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Schedules One Time Settings - Create set" })
          this.ehm__unhandledErrorMessage = "";
          this.handleChangeFT("newSet");
        }
      } catch (err) {
        const errors = [];
        if (err.errors && err.errors.length > 0) {
          this.ehm__errorMessages(err, true);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            if (
              el.message === "name length must be at least 3 characters long"
            ) {
              errors.push({
                message: this.$t(
                  "Comn_name_length_must_be_at_least_3_characters_long"
                )
              });
            } else {
              errors.push({ message: el.message });
            }
          });
          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });

          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
        // this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    calculateDuration() {
      this.feedTemplate.timeSlots = this.feedTemplate.timeSlots.map(
        (timeSlot) => {
          if (timeSlot.s_time && timeSlot.e_time) {
            const [shour, smin] = timeSlot.s_time.split(":");
            const [ehour, emin] = timeSlot.e_time.split(":");

            const sDate = this.dhm__dateUtilsLib.set(
              this.dhm__getTodayInUserTZ,
              {
                hours: shour,
                minutes: smin
              }
            );
            const eDate = this.dhm__dateUtilsLib.set(
              this.dhm__getTodayInUserTZ,
              {
                hours: ehour,
                minutes: emin
              }
            );

            let durationMins = this.dhm__dateUtilsLib.differenceInMinutes(
              eDate,
              sDate
            );
            if (shour === "23" && ehour === "00") {
              durationMins = sDate.diff(eDate, "minutes");
            }
            // console.log(s_time, e_time, durationMins)
            timeSlot.duration = durationMins;
            return timeSlot;
          }
        }
      );
    },
    async deleteTemplate(id) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.$confirm(
          this.$t("do_you_want_to_delete_set"),
          this.$t("Comn_delete"),
          {
            confirmButtonText: this.$t("Comn_delete"),
            cancelButtonText: this.$t("Comn_cancel"),
            type: "warning",
            center: true
          }
        );
        this.loading = true;
        await this.$store.dispatch("schedules/deleteFeedTemplate", id);
        this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Schedules One Time Settings - Delete" })
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("PM_set_deleted_successfully"),
          duration: 5000,
          type: "success"
        });
        this.loading = false;
      } catch (err) {
        // console.log(err);
        if (err.response) {
          this.$notify({
            title: this.$t("failed"),
            message: this.$t("Something_went_wrong"),
            duration: 5000,
            type: "error"
          });
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
        console.log(this.loading);
      }
    },
    ehm__error422Handler: function (err) {
      console.log(err.response.data);
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        err.response.data.errors.details.forEach((el, index) => {
          // errorInnerHtml += '<li>' + el.message + '</li>'
          this.ehm__unhandledErrorMessage += el.message + ",";
        });
      } else {
        this.ehm__unhandledErrorMessage += err.response.data.message + ",";
      }
      this.ehm__unhandledErrorMessage = this.ehm__unhandledErrorMessage.trim();
    },
    formatErrors(errorObj) {
      errorObj.forEach((error) => {
        if (error.message === "pls_enter_title_to_set") {
          error.message = this.$t("SET_alias_must_not_be_empty");
        } else if (error.message === "PM_sch_is_overlapping_msg") {
          const { position1, position2 } = error;
          error.message = this.$t("PM_sch_is_overlapping_msg", {
            position1,
            position2
          });
        } else if (error.message === "total_should_sum_to_threshold") {
          error.message = this.$t("PM_tf_percentage_must_be_100");
        } else if (error.message === "PM_end_time_not_less_start_time") {
          const { message, position } = error;
          error.message = this.$t(message, { position });
        } else if (error.message === "PM_start_time_not_less_than_0015") {
          const { message, position } = error;
          error.message = this.$t(message, { position });
        } else if (error.message === "PM_end_time_less_than_end_time") {
          const { message, position, end_time } = error;
          error.message = this.$t(message, { position, end_time });
        } else if (
          error.message === "name length must be at least 3 characters long"
        ) {
          error.message = this.$t("Name_characters_length_validation");
        }
      });
    }
  }
};
</script>

<style lang="scss">
.feedTemplate {
  background: white;
  border-top: 1px solid #dddddd;

  .feed-template__wrap {
    @include responsiveProperty(height, 70vh, 95vh, 70vh);
  }

  .feed-template-scroll {
    .feed-template__warp-no-text {
      display: flex !important;
      align-items: center !important;
    }
  }

  .el-scrollbar__view {
    padding-right: 7px;
  }
  .feed-table {
    .el-input__inner {
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    .alias-name {
      margin-top: 10px;
      display: flex;
      align-items: center;
      label {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
        line-height: 20px;
        color: #0a2463;
      }
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-input__inner {
      text-align: left;
      width: 93px;
    }

    .el-table th.is-leaf {
      border-bottom: 1px solid #ebeef5;
      font-weight: normal;
    }
    .el-table th {
      padding-left: 8px;
    }

    .el-table__append-wrapper {
      border-top: 0.5px solid #ebeef5;
      .left-btn-container {
        padding: 12px;
        display: flex;
        justify-content: center;
      }
    }

    .el-form-item__label {
      margin-left: 10px;
    }
  }
  .el-table {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    border-collapse: separate;
    border-spacing: 0px;
    margin: 10px 0px;
    &::before {
      height: 0px;
    }
    td,
    th {
      border-bottom: 0px;
    }
    .alias-name-column {
      padding: 0px 12px 24px 12px;
    }
    .row-header {
      text-align: left;
      padding-left: 10px;
      color: #0a2463;
      font-weight: normal;
    }
    .row-header-column-name {
      // width: 27%;
      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      font-weight: normal;
    }
    td:not(:first-of-type),
    th:not(:first-of-type) {
      border-bottom: 0px;
      padding: 0px;
      text-align: left;
      .el-form-item--mini.el-form-item,
      .el-form-item--small.el-form-item {
        margin-bottom: 0px;
      }
    }
    .el-time-picker {
      width: 100px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100px;
    }

    .feed-percent-container {
      width: 20%;
      input {
        width: 60px;
      }
      .el-input__inner {
        padding: 0 5px;
      }
    }
    .time-container {
      .el-input__inner {
        padding-right: 0px;
        width: 92px;
      }
    }
  }

  .feed-temp-r-section {
    overflow-y: scroll;
    @include responsiveProperty(height, 466px, 574px, 800px);

    border-left: 1px solid #dddddd;
    // display: flex;
    justify-content: center;
    padding-top: 22px;
    padding-right: 22px;

    .set-data-container {
      .set-title {
        color: #0a2463;
        opacity: 0.8;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        line-height: 22px;
      }
      .sets-data {
        table {
          background: #fdfdfd;
          border: 1px solid #dddddd;
          box-sizing: border-box;
        }
        .el-table {
          border-collapse: separate;
          border-spacing: 0px;
        }
        .el-table td,
        .el-table th.is-leaf {
          border-bottom: 1px solid #ebeef5;
        }
        th.is-leaf {
          color: #0a2463;
          font-weight: normal;
          padding-top: 12px;
          padding-bottom: 12px;
          background: #fdfdfd;
        }
        .el-table tr {
          background-color: transparent;
        }
      }
    }
  }

  .feed-temp-l-section {
    padding: 22px 0px;
    .create-new-set {
      display: flex;
      align-items: center;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      @include responsiveProperty(line-height, 22px, 27px, 28px);
      color: #0a2463;
      opacity: 0.9;
      .create-new-set-image {
        margin-right: 8px;
        @include responsiveProperty(width, 16px, 22px, 25px);
      }
    }

    th.is-leaf {
      font-weight: 500;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
      line-height: 20px;
      color: #0a2463;
      background: #fdfdfd;
    }
    .el-table td {
      @include responsiveProperty(padding, 1px 0px, 10px 0px, 10px 0px);
    }
  }
}
</style>
