<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: assignPondsAndResources.vue
Description:  This file contians UI components used to assign resources to ponds. It is being used in schedules tab in settings
-->
<template>
  <el-row class="schedules-resources-container">
    <el-col :span="24" v-loading="feedLoading" class="inside-container">
      <el-col style="padding: 16px 16px 0px 16px">
        <el-row>
          <el-col>
            <h4>{{ $t("PM_assign_resource_Pond_short") }}</h4>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="assign-resources-row-style">
          <el-col :span="9">
            <er-select
              v-model="resourceSelected"
              :placeholder="$t('select_feed')"
              value-key="_id"
              size="mini"
              @change="handleChangeFeedTemplate"
            >
              <el-option
                v-for="resource in getSortedResources"
                :key="resource._id"
                :label="`${resource.name}-${resource.feed_type}`"
                :value="resource"
              ></el-option>
            </er-select>
          </el-col>
          <el-col
            :span="9"
            :key="getPonds.length"
            class="header-pond-container"
          >
            <er-select
              v-model="resourceSelected.ponds"
              :placeholder="$t('Ponds_select_pond_name')"
              filterable
              :key="resourceSelected._id"
              :disabled="!resourceSelected._id"
              multiple
              collapse-tags
              size="mini"
              value-key="_id"
              @change="handlePondsSelectionChange"
            >
              <template v-for="pond in getPonds">
                <el-option
                  :key="pond._id"
                  :label="pond.title"
                  :value="pond._id"
                  >{{ pond.title }}</el-option
                >
              </template>
            </er-select>
          </el-col>
          <el-col :span="6">
            <layout-toolbar class="btn-container">
              <er-button
                btnType="save"
                size="mini"
                :showLabel="true"
                :showIcon="true"
                :disabled="
                  isReadOnly ||
                    !resourceSelected._id ||
                    !resourceSelected.ponds ||
                    resourceSelected.ponds.length === 0
                "
                :loading="feedLoading"
                @click="updateFeedToPond"
              />

              <er-button
                btnType="cancel"
                size="mini"
                :showLabel="true"
                :disabled="isReadOnly"
                @click="handleClear($event, 'resource')"
              />
            </layout-toolbar>
          </el-col>
        </el-row>
        <el-row>
          <hr />
        </el-row>
      </el-col>
      <el-col class="table-container">
        <er-data-tables
          ref="assignResourcetoPonds"
          size="mini"
          :tableData="getTableData"
          :key="$i18n.locale"
          :actionColumn="false"
          :el-table-props="tableProps"
          uniqueId="schedules-assign-resources-settings__table"
          :columns="tableColumns"
          :page-size="$constants.table['page-size']"
          :pagination-props="$constants.table['pagination-props']"
          type="transparent-header-table"
        >
          <template slot="empty">
            <span>
              <img class="no-data__img" src="@/assets/no_data.svg" />
            </span>
            <span class="no-data__text">
              <h4>{{ $t("Comn_no_data_connection") }}</h4>
            </span>
          </template>
          <el-table-column
            prop="name"
            :label="$t('Resource_name')"
            :width="150"
          ></el-table-column>
          <template v-slot:name="{ row }">
            <p v-if="row.data.feed_type">
              {{ `${row.data.name}-${row.data.feed_type}` }}
            </p>
            <p v-else>{{ `${row.data.name}` }}</p>
          </template>
          <template v-slot:ponds="{ row }">
            <div class="pm-tag-list" v-if="row.data.ponds.length > 0">
              <el-tag
                v-for="pond in row.data.ponds"
                :disable-transitions="true"
                :key="pond._id"
                effect="dark"
                size="mini"
                :closable="true"
                @close="handleRemovePond(row.data, pond._id)"
                >{{ pond.title }}</el-tag
              >
            </div>
            <span v-else>
              <el-tag size="mini" effect="dark" type="info">
                {{ $t("Comn_na") }}
              </el-tag>
            </span>
          </template>
        </er-data-tables>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import FeedTemplate from "@/model/feedtemplate";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [errorHandlerMixin],
  props: ['tabData'],
  data: function() {
    return {
      feedTemplateSelected: new FeedTemplate(),
      resourceSelected: {
        _id: "",
        ponds: []
      },
      ponds: [],
      pondsLoading: false,
      feedLoading: false,
      selectedFTClearPonds: [],
      tableProps: {
        size: "small",
        defaultSort: {
          prop: "name",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      }
      // pondsNotInTemplate: []
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    disablePondOptionInSet() {
      return (pondId) => {
        /**
         * Disable if Pond Not in the selected template
         * ,assgined to other templates,
         * not in the ponds which are removed in frontend
         */
        return (
          this.feedTemplateSelected.ponds.indexOf(pondId) === -1 &&
          this.pondsNotInTemplate.indexOf(pondId) === -1 &&
          this.selectedFTClearPonds.indexOf(pondId) === -1
        );
      };
    },
    tableColumns() {
      return {
        name: {
          prop: "name",
          label: this.$t("Comn_feed_name"),
          sortable: true,
          minWidth: 100
        },
        ponds: {
          prop: "ponds",
          label: this.$t("assigned-ponds"),
          // sortable: true
          minWidth: 300
        }
      };
    },
    disablePondOptionInResource() {
      return (pondId) => {
        /**
         * Disable if Pond Not in the selected template
         * ,assgined to other templates,
         * not in the ponds which are removed in frontend
         */

        // diabale if already it has a feed
        const currentPond = this.ponds.filter(({ _id }) => _id === pondId)[0];
        return !!currentPond.feed_type;
        // return false;
      };
    },
    currFeedTemplate: {
      get() {
        return this.$store.getters["schedules/getCurrentFeedTemplate"];
      },
      set(value) {
        this.$store.dispatch("schedules/setCurrentFeedTemplate", value);
      }
    },
    FeedTemplates() {
      return Object.values(this.$store.getters["schedules/getMapftIdFT"]);
    },
    ObjPondIdPond() {
      return this.$store.getters["pond/getPondsObj"];
    },
    pondsNotInTemplate() {
      return this.$store.getters["schedules/getPondsNotInTemplate"].map(
        (pond) => {
          return pond._id;
        }
      );
    },
    pondsInTemplate() {
      return this.$store.getters["schedules/getPondsInTemplate"];
    },
    resources: function() {
      return this.$store.getters["resource/getArrResources"];
    },
    getTableData() {
      if (!this.$lodash.cloneDeep(this.resources)) return [];
      return this.$lodash
        .cloneDeep(this.resources)
        .map(({ _id, name, feed_type }) => {
          const pondsWithRes = this.ponds.filter(
            (pond) => pond.feed_type === _id
          );
          const pondsWithResFormated = pondsWithRes.map(
            ({ _id, location_id, title }) => {
              return {
                _id,
                location_id,
                title
              };
            }
          );

          return {
            _id,
            name,
            feed_type,
            ponds: pondsWithResFormated
          };
        });
    },
    getSortedResources() {
      return this.$lodash
        .cloneDeep(this.resources)
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.name, b.name)
        );
    },
    getPonds() {
      const availablePonds = this.$lodash
        .cloneDeep(this.ponds)
        .filter(({ feed_type }) => {
          return !feed_type;
        });
      const filteredPonds = this.$lodash
        .cloneDeep(this.ponds)
        .filter(
          (item) =>
            !this.getSortedResources.map((x) => x._id).includes(item.feed_type)
        );

      if (filteredPonds.length) {
        return filteredPonds.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
      } else {
        return availablePonds.sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
      }
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent() {
      try {
        const res = await this.fetchAllPonds({
          location_id: this.getCurrUserLocation._id,
          get_all: true,
          status: ["ACTIVE", "INACTIVE"]
        });

        this.ponds = res.ponds;
        this.feedTemplateSelected = new FeedTemplate();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.assignResourcetoPonds) {
            this.$refs.assignResourcetoPonds.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
      }
    },
    getPondTitle(pondId) {
      return (this.ObjPondIdPond[pondId] || { title: this.$t("empty") }).title;
    },
    async handleClear($event, componentName) {
      if (componentName === "feed") {
        this.selectedFTClearPonds = this.feedTemplateSelected.ponds;
        this.feedTemplateSelected.ponds = [];
      } else {
        this.resourceSelected = {
          _id: "",
          ponds: []
        };
      }
    },
    handleChangeFeedTemplate: function(value) {
      this.feedTemplateSelected = this.$lodash.cloneDeep(value);
      this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Assign Resource to Pond - resource Dropdown" })
    },
    async updateFeedToPond(type) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.feedLoading = true;
        const resourcePayload = {};
        resourcePayload.feed_type_id = this.resourceSelected._id;
        resourcePayload.pond_ids = this.resourceSelected.ponds;
        await this.$store.dispatch("schedules/addFeedToPonds", resourcePayload);
        this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Assign Resource to Pond - Save" })
        await this.initComponent();

        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message:
            type === "table"
              ? this.$t("Resource_updated_successfully")
              : this.$t("Resource_assigning_successfully"),
          duration: 5000,
          type: "success"
        });
        this.resourceSelected.ponds = [];
      } catch (err) {
        const errors = [];

        if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.feedLoading = false;
      }
    },
    handlePondsSelectionChange(arrPondIds) {
      // this.resourceSelected;
      this.mixPanelEventGenerator({ eventName: "Settings - Schedules - Assign Resource to Pond - Ponds Dropdown" })
    },
    async handleRemovePond(data, pondId) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        this.feedLoading = true;

        await this.$store.dispatch("schedules/deleteFeedFromPond", pondId);
        await this.initComponent();

        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Resource_deletion_successfully"),
          duration: 5000,
          type: "success"
        });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.feedLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.schedules-resources-container {
  @include responsiveProperty(--table-gutter, 308px, 318px, 325px);
  border-top: 1px solid #dddddd;
  padding: 10px;
  background: white;
  display: flex;
  justify-content: center;
  .inside-container {
    background: #fcfcfc;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 10px;
  }
  hr {
    color: #f0f0f0;
    margin-bottom: 2px;
  }
  .table-container {
    padding-left: 29px;
    padding-right: 29px;
    // padding-bottom: 29px;
    .el-tag--dark {
      height: unset;
      display: inline-flex;
      align-items: center;
      padding: 0px 7px;
      background-color: #f2f0f9 !important;
      border-color: #f2f0f9;
      font-size: 11px;
      color: #0a2463;
      margin-top: 2px;
      margin-right: 2px;
      border-radius: 20px;

      .el-tag__close.el-icon-close {
        background-color: #0a2463;
        font-size: 11px;
        top: 0px;
      }
      .el-tag__close {
        color: #fff !important;
      }
    }

    .el-tag .el-icon-close {
      border-radius: 50%;
      text-align: center !important;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }

    .el-tag--mini .el-icon-close {
      margin-left: -3px;
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
    }
    .pm-tag-list {
      overflow: auto;
    }
  }
  h4 {
    // margin-bottom: 17px;
    font-size: 13px;
    line-height: 24px;
    color: #0a2463;
    opacity: 0.9;
  }
  .assign-resources-row-style {
    margin-top: 10px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-container {
      display: flex;
      justify-content: flex-end;
    }
    .header-pond-container {
      .el-input {
        width: 220px;
      }
    }
  }
}
</style>
