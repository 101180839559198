<template>
  <er-dialog
    width="28%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @beforeClose="handleCloseDialog"
    :title="$tc(`Ponds_select_pond_mothers`, 2)"
    class="action-on-pond"
    custom-class="add_pm_to_shrimptalk"
  >
    <el-input
      v-model="searchPmCode"
      @input="handleSearchPM($event)"
      size="mini"
      :placeholder="`${$t('Comn_search')} ${$t('Comn_pondmother_code')}`"
    ></el-input>
    <div style="position: relative">
      <el-tag class="error-message-tag" v-if="getPondMothers.length === 0">
        <i18n
          path="no_feeders_available_or_pond_dont_have_feeders"
          tab="label"
          for="assign_pondmothers"
        ></i18n>
        <a href="/user/settings?tab=devices">
          {{ $t("assign_pondmothers") }}
        </a>
      </el-tag>
      <ul class="st-pm-list er-list" v-else>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          class="er-list-item stick select-all-checkbox"
          @change="handleSelectAll"
        >
          <p>{{ $t("select_all") }}</p>
          <p>{{ $t("Comn_main") }}</p>
        </el-checkbox>
        <el-checkbox-group
          v-model="checkedPondMothers"
          :max="selectedStForDialog.supports.pms_count"
          @change="handleChangeInPMSelection($event)"
        >
          <el-checkbox
            v-for="pm in getPondMothers"
            :label="pm._id"
            :key="pm.code"
            class="er-list-item"
            :disabled="disablePmSelectOption(pm, selectedStForDialog)"
          >
            {{ pm.code }}
            <el-radio
              :label="pm._id"
              :key="`${pm.code}-radio`"
              v-model="mainPmId"
              :disabled="checkedPondMothers.indexOf(pm._id) === -1"
            >
              {{ "" }}
            </el-radio>
          </el-checkbox>
        </el-checkbox-group>
      </ul>
    </div>

    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :loading="dialogLoading"
        :showIcon="true"
        :showLabel="true"
        @click="handleFullChangeInPmSelection($event, selectedStForDialog)"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :loading="dialogLoading"
        :showLabel="true"
        @click="handleCloseDialog"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import ShrimpTalk from "@/model/shrimptalk";
import { mapGetters, mapActions } from "vuex";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  props: {
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    },
    pondPmsData: {},
    stDetails: {}
  },
  data: function() {
    return {
      loading: false,
      dialogLoading: false,
      selectedStForDialog: new ShrimpTalk(),
      searchPmCode: "",
      checkedPondMothers: [],
      pondMothers: [],
      checkAll: false,
      isIndeterminate: false,
      mainPmId: undefined
      //   pondPmsData: {},
    };
  },

  computed: {
    ...mapGetters({
      mapStIdSt: "shrimptalk/getMapStidSt"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),

    isReadOnly() {
      return this.$store.getters["user/isReadOnly"];
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    getPondMothers() {
      return this.getListOfPondMothers().sort((a, b) =>
        this.$commonUtils.alphaNumericComparator(a.code, b.code, 1)
      );
    }
  },
  mounted() {
    this.upm__setDataKeys("code", "title");
  },
  methods: {
    ...mapActions({
      fetchAllPondMothers: "pondmother/fetchAllPondMothers",
      fetchAllShrimpTalks: "shrimptalk/fetchAllShrimpTalks",
      updateShrimpTalkDetails: "shrimptalk/updateShrimpTalkDetails",
      updatePMSettings: "pondmother/updatePMSettings"
    }),
    disablePmSelectOption(pondmother, shrimptalk) {
      const conditions = [
        pondmother => pondmother.shrimp_talk_id,
        pondmother => this.mapStIdSt.get(pondmother.shrimp_talk_id),
        pondmother =>
          pondmother.pond_id ===
          this.mapStIdSt.get(pondmother.shrimp_talk_id).pond_id,
        pondmother => pondmother.shrimp_talk_id !== shrimptalk._id
      ];
      // conditions.map((item) => console.log())
      return conditions.every(x => {
        console.log('x hi', x);
        return x(pondmother);
      });
    },
    handleChangeInPMSelection(selectedPms) {
      if (selectedPms.length >= this.selectedStForDialog.supports.pms_count) {
        this.checkAll = true;
        this.isIndeterminate = false;
        return;
      }
      if (selectedPms.length > 0) {
        this.checkAll = false;
        this.isIndeterminate = true;
        if (!selectedPms.includes(this.mainPmId)) {
          this.mainPmId = selectedPms[0];
        }
        return;
      }
      this.checkAll = false;
      this.isIndeterminate = false;
      this.mainPmId = undefined;
    },

    handleSelectAll(event) {
      if (event) {
        const st = this.selectedStForDialog;
        const listPMs = Object.values(this.getPondMothers);
        const pondMothers = listPMs
          .filter(
            pm =>
              pm.shrimp_talk_id === undefined || pm.shrimp_talk_id === st._id
          )
          .slice(0, st.supports.pms_count);
        this.checkedPondMothers = pondMothers
          .filter(x => x._id)
          .map(x => x._id);
        this.mainPmId = this.checkedPondMothers[0];
        this.checkAll = true;
        this.isIndeterminate = false;
      } else {
        this.checkedPondMothers = [];
        this.checkAll = false;
        this.isIndeterminate = false;
        this.mainPmId = undefined;
      }
    },

    async handleFullChangeInPmSelection() {
      try {
        this.dialogLoading = true;
        const payload = this.checkedPondMothers
          .map(x => this.pondPmsData[this.selectedStForDialog.pond_id][x])
          .reduce(
            (acc, x) => {
              acc.pond_mothers.push({
                _id: x.pond_mother_id ? x.pond_mother_id : x._id,
                is_master: (x.pond_mother_id || x._id) === this.mainPmId,
                code: x.code
              });
              return acc;
            },
            {
              shrimp_talk_id: this.selectedStForDialog.shrimp_talk_id,
              title: this.selectedStForDialog.title,
              pond_mothers: []
            }
          );
          if (this.selectedStForDialog.block_shrimp_talk) {
            const PMPayload = [];
            await payload.pond_mothers.map( val => {
              if (this.pondPmsData[this.selectedStForDialog.pond_id][val._id].mode === "AUTOMATIC") {
                PMPayload.push({ mode: "SCHEDULE", pond_mother_id: val._id, shrimp_talk_id: this.selectedStForDialog._id })
              }
            })
            if (PMPayload.length > 0) {
              await this.updatePMSettings(PMPayload);
            }
          }
        await this.updateShrimpTalkDetails([payload]);
        this.ehm__errorMessagesObject = new ShrimpTalk();
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Shrimp_talk_details_updated_sucess"),
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$emit("close_dialog", false, "refresh");
        this.dialogLoading = false;
      }
    },
    handleSearchPM(event) {
      this.searchPmCode = event;
    },
    getListOfPondMothers() {
      if (
        Object.values(this.pondPmsData).length === 0 ||
        !this.selectedStForDialog.pond_id ||
        this.selectedStForDialog.pond_id === ""
      ) {
        return [];
      }
      const allPondPms = this.$lodash.cloneDeep(
        Object.values(this.pondPmsData[this.selectedStForDialog.pond_id])
      );
      const pondMothers =
        this.searchPmCode !== ""
          ? allPondPms.filter(x =>
              x.code.toLowerCase().includes(this.searchPmCode.toLowerCase())
            )
          : allPondPms;
      return pondMothers.sort((a, b) =>
        this.$commonUtils.alphaNumericComparator(a.code, b.code, 1)
      );
    },

    handleDialogOpen() {
      this.checkedPondMothers = this.$lodash
        .cloneDeep(this.stDetails.pond_mothers)
        .filter(x => x._id)
        .map(x => x._id);
      const masterPm = this.stDetails.pond_mothers.filter(x => x.is_master);
      this.mainPmId = undefined;
      if (masterPm.length > 0) {
        this.mainPmId = masterPm[0]._id;
      }
      this.checkAll =
        this.checkedPondMothers.length === this.stDetails.supports.pms_count;
      this.isIndeterminate =
        this.checkedPondMothers.length > 0 &&
        this.checkedPondMothers.length < this.stDetails.supports.pms_count;
      this.searchPmCode = "";
      this.selectedStForDialog = this.$lodash.cloneDeep(this.stDetails);
      this.getListOfPondMothers();
    },
    handleCloseDialog(event) {
      this.$nextTick(() => {
        this.selectedStForDialog = new ShrimpTalk();
        this.checkedPondMothers = [];
        this.mainPmId = undefined;
        this.isIndeterminate = false;
        this.checkAll = false;
      });
      this.$emit("close_dialog", event);
    }
  }
};
</script>

<style lang="scss">
.action-on-pond {
  $width: 200px;
  .el-dialog__body {
    padding: 15px 15px;
  }
  .input-pond-name {
    width: $width !important;
  }
  .row-size {
    position: relative;
    width: $width;
    .select-size-units {
      .el-input__inner {
        padding-right: 26px;
        @include responsiveProperty(width, 100px, 120px, 140px);
      }

      width: unset;
      box-sizing: border-box;
      position: relative;
    }
    .input-pond-size {
      width: unset;
      box-sizing: border-box;
      position: relative;
      .el-input__inner {
        width: 100px;
      }
    }
  }
  .input-pl-stocked {
    width: $width !important;
    .el-input__inner {
      text-align: left !important;
    }
  }
  .date-picker-stock-date {
    width: $width !important;
    .el-input__inner {
      text-align: left;
    }
  }
}

.add_pm_to_shrimptalk {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 205px, 227px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 100px, 130px, 190px);
  }
}
</style>
