<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import { UAM_UI_STATES } from "@/constants/uiStates";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin, filtersMixin, redirectsMixin],
  inject: ["parentConfig"],
  data() {
    return {
      UAM_UI_STATES
    };
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getSidePanelState: "getSidePanelState"
    }),
    ...mapGetters("user", {
      getCurrUserProfile: "getUserProfile"
    }),
    showUserActionButtons() {
      console.log("this.getUserData", this.getUserData);
      return !["ACCOUNT_MANAGER_SUB_USER", "ACCOUNT_MANAGER"].includes(this.getUserData.user_type);
    },
    getUserTypeLangStrs() {
      return this.parentConfig.USER_TYPE;
    },
    getSidePanelUIState() {
      return this.getSidePanelState.UI_STATE;
    },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    getUAMTabData() {
      return this.parentConfig.uamTabData;
    }
  },
  render: function(h) {
    const uiStateToView = {
      [this.UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS]: this.getUserDetailsView,
      [this.UAM_UI_STATES.UPDATE_SUB_USER_PASSWORD]: this
        .getSubUserUpdatePasswordView,
      [this.UAM_UI_STATES.CREATE_NEW_SUB_USER_DETAILS]: this
        .getSubUserAddNewView,
      [this.UAM_UI_STATES.EDIT_SUB_USER_DETAILS]: this
        .getSubUserUpdateDetailsView,
      [this.UAM_UI_STATES.NO_USER_SELECTED]: this.getNoUserSelected
    };
    return (
      <el-col class="right-panel-toolbar">
        {uiStateToView[this.getSidePanelUIState](h)}
      </el-col>
    );
  },
  methods: {
    ...mapActions("userAccessManagement", {
      changeSidePanelUIState: "changeSidePanelUIState"
    }),
    getUserDetailsView(h) {
      return (
        <layout-toolbar>
          <span class="material-icons-round">supervisor_account</span>
          <p class="panel-title">
            {this.ftm__capitalize(this.getUserData.full_name)}
          </p>
          <el-tag size="medium">
            {this.ftm__capitalize(
              this.$tc(this.getUserTypeLangStrs[this.getUserData.user_type], 1)
            )}
          </el-tag>
          <div class="filler"></div>
           {this.showUserActionButtons ? this.getUserActionButtons(h) : <p></p>}
        </layout-toolbar>
      );
    },
    getUserActionButtons(h) {
      return (
         <div>
            <er-button
              btnType="outlinePassword"
              showLabel={true}
              showIcon={true}
              size="mini"
              on-click={() =>
                this.handleClick(this.UAM_UI_STATES.UPDATE_SUB_USER_PASSWORD)
              }
            >
              <slot>
                <p>{this.$t("Comn_chng_pwd")}</p>
              </slot>
            </er-button>
            <er-button
              btnType="outlineEdit"
              showLabel={true}
              showIcon={true}
              size="mini"
              on-click={() =>
                this.handleClick(this.UAM_UI_STATES.EDIT_SUB_USER_DETAILS)
              }
            >
              <slot>
                <p>{`${this.$t("Comn_edit")} info`}</p>
              </slot>
            </er-button>
          </div>
      );
    },
    getSubUserUpdatePasswordView(h) {
      return (
        <layout-toolbar justify="start">
          <span class="material-icons-round">enhanced_encryption</span>
          <p class="panel-title">{this.$t("Comn_chng_usr_pwd")}</p>
        </layout-toolbar>
      );
    },
    getSubUserAddNewView(h) {
      return (
        <layout-toolbar justify="start">
          <span class="material-icons-round">person_add</span>
          <p class="panel-title">{`${this.$t("Usrs_new_user")}`}</p>
        </layout-toolbar>
      );
    },
    getSubUserUpdateDetailsView(h) {
      return (
        <layout-toolbar justify="start">
          <span class="material-icons-round">manage_accounts</span>
          <p class="panel-title">{`${this.$t("Usrs_edit_user")}`}</p>
        </layout-toolbar>
      );
    },
    getNoUserSelected(h) {
      return <div></div>;
    },
    async handleClick(sidePanelUIState, userIndex = 0) {
      try {
        this.$gblUAMCanUserEdit(this.getUAMTabData);
        if (this.getUserData._id === this.getCurrUserProfile._id) {
          this.rdm__navigateToPathWithLocationId({
            query: { tab: "userProfile" }
          });
          return;
        }
        await this.changeSidePanelUIState(sidePanelUIState);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.right-panel-toolbar {
  line-height: 28px;
  overflow: hidden;
  color: $primary-color;
  .panel-title {
    @include normal-text;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-tag {
    color: $primary-color;
    @include small-text;
  }
}
</style>
