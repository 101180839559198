<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: settings.vue
Description:This file contains the tab components.userProfile,units,userAccessManagement,pondTab,devices,pondMotherSettings,scheduleSettings,gateway,shrimpTalkSettings,pondGuardSettings,feedBlowerSettings,locationManagement,aquasim
-->
<template>
  <div class="help-section-main-container">
    <div class="header-main-container" v-if="computedComponentName === 'releaseNotes'">
      <div class="header-item-container">
        <div class="header-item-container__input-box">
          <el-input
            v-show="search"
            clearable
            class="search-feature-input"
            :placeholder="getSearchPlaceholder"
            :debounce="500"
            v-model="searchStr"
            @change="handleChange"
            @blur="handleBlur"
            @clear="handleClear"
          ></el-input>
        </div>
      </div>
      <div class="header-item-container__view">
        <div class="header-item-container__input-box">
          <el-select
            v-model="selectedView"
            placeholder="Select"
            @change="handleChangeView"
            class="device-view-dropdown"
          >
            <el-option
              v-for="item in viewContainer"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <layout-pondlogs class="help-section-tab-component">
      <template slot="layout-pondlogs-scrollable-main">
        <er-card class="custom-card">
          <er-top-route-nav
            type="tabs"
            :items="computedTabItems"
          ></er-top-route-nav>
          <!-- </simplebar> -->
          <component
            :is="computedComponentName"
            @change-tab="handleChangeTabTo"
            :tabData="components[computedComponentName]"
          ></component>
        </er-card>
      </template>
    </layout-pondlogs>
  </div>
</template>

<script>
import releaseNotes from "@/components/helpSection/releaseNotes";
import { ALL_USERS as FEATURES_ALL_USERS } from "@/middleware/featureToggleManager";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import {
  userTypesWithAccess,
  isValidTabName,
  getFirstTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
import featureToggleMixin from "@/mixins/featureToggleMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import filtersMixin from "@/mixins/filtersMixin";
import eventBus from "@/components/shared/eventBus";
import productManuals from "@/components/superadmin/adminHelpSection/adminProductManuals";
// import faqs from "@/components/superadmin/adminHelpSection/adminFaqs";
import abbreviations from "@/components/superadmin/adminHelpSection/adminAbbreviations";
import { mapActions } from 'vuex';
const tabToPermission = {
  releaseNotes: {
    name: "releaseNotes",
    keyToValidateAccess: "USER_TYPES",
    USER_TYPES: userTypesWithAccess,
    featureAllowedUsers: FEATURES_ALL_USERS
  },
  productManuals: {
    name: "productManuals",
    keyToValidateAccess: "USER_TYPES",
    USER_TYPES: userTypesWithAccess,
    featureAllowedUsers: FEATURES_ALL_USERS
  },
  // faqs: {
  //   name: "faqs",
  //   keyToValidateAccess: "USER_TYPES",
  //   USER_TYPES: userTypesWithAccess,
  //   featureAllowedUsers: FEATURES_ALL_USERS
  // },
  abbreviations: {
    name: "abbreviations",
    keyToValidateAccess: "USER_TYPES",
    USER_TYPES: userTypesWithAccess,
    featureAllowedUsers: FEATURES_ALL_USERS
  }
};
export default {
  mixins: [featureToggleMixin, redirectsMixin, filtersMixin],
  components: {
    releaseNotes,
    productManuals,
    // faqs,
    abbreviations
  },
  data: function() {
    return {
      componentName: "releaseNotes",
      search: true,
      searchStr: "",
      searchStrKey: "feature_title",
      viewContainer: [
        {
          value: "web",
          label: this.$t("web")
        },
        {
          value: "android",
          label: this.$t("android")
        },
        {
          value: "ios",
          label: this.$t("ios")
        }
      ],
      selectedView: "web"
    };
  },
  computed: {
    computedComponentName: function() {
      return this.$route.query.tab;
    },
    getSearchPlaceholder() {
      return this.$t("Comn_search");
    },
    components() {
      return {
        releaseNotes: {
          label: this.$t("Release_notes"),
          ...tabToPermission.releaseNotes
        },
        productManuals: {
           label: this.$t("product_manuals"),
          ...tabToPermission.productManuals
        },
        // faqs: {
        //   label: this.$t("faqs"),
        //   ...tabToPermission.faqs
        // },
        abbreviations: {
           label: this.$t("abbreviations"),
          ...tabToPermission.abbreviations
        }
      };
    },
    computedTabItems: function() {
      const filters = [
        this.$gblUAMFilterItemsUserCanView,
        this.ftgm__filterObjFeatures
      ];
      return filters.reduce((accComponents, filter) => {
        accComponents = filter(accComponents);
        return accComponents;
      }, this.components);
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((component) => {
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/releaseNotes",
          query: {
            tab: getFirstTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType
            )
          }
        });
      }
    });
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleChangeTabTo: function(tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName }
      });
    },
    handleBlur(event) {
      if (this.searchStr === "") {
        eventBus.$emit("cleared", {});
      }
    },
    handleChangeView(newVal) {
      console.log("newVal", newVal);
      this.mixPanelEventGenerator({ eventName: "Help - Release Notes - Platform Dropdown" });
      this.selectedView = newVal;
      eventBus.$emit("viewChanged", {
        selectedView: this.selectedView.toLocaleUpperCase()
      });
    },
    handleChange(string) {
      this.loading = true;
      try {
        const query = {};
        if (string === "") {
          query.page = 1;
          query.limit = 30;
          if (query[this.searchStrKey]) {
            delete query[this.searchStrKey];
          }
          eventBus.$emit("cleared", { query, infinite_scroll: false });
        } else {
          this.mixPanelEventGenerator({ eventName: "Help - Release Notes - Search" });
          query[this.searchStrKey] = string?.trim();
        }
        eventBus.$emit("loadData", { query, infinite_scroll: false });
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleClear() {
      this.loading = true;
      try {
        const query = {};
        query.page = 1;
        query.limit = 30;
        // query.get_all = true;
        eventBus.$emit("cleared", { query, infinite_scroll: false });
      } catch (err) {
        console.log(err);
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.help-section-main-container {
  position: relative;
  .header-main-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 18px;
    right: -58px;
  }
  .header-item-container {
    z-index: 10;

    &__input-box {
      width: 300px;
      el-select {
        width: 200px !important;
      }
      .search-feature-input {
        input {
          height: 28px !important;
          line-height: 28px;
        }
        .el-input__suffix {
          margin-bottom: 5px;
        }
      }
    }
  }
  .header-item-container__view {
    .el-select {
      width: 200px !important;
    }
    z-index: 10;
    margin-left: 15px;
    &__input-box {
      .el-select {
        width: 200px !important;
      }
      .el-input__inner {
        height: 28px !important;
        line-height: 28px;
      }
    }
    .device-view-dropdown {
      .el-select-dropdown__item {
        font-size: 11px;
        // @include responsiveProperty(
        //   font-size,
        //   $app_font_size_small,
        //   $app_font_size_1,
        //   $app_font_size_2
        // );
        display: flex;
        align-items: center;
      }
      .el-select-dropdown {
        min-width: 120px !important;
      }
      min-width: 120px !important;
    }
  }
  .help-section-tab-component {
    $default-font-size: 13px;
    $font-color: #404243;
    .el-menu--horizontal.type-tab {
      display: flex;
      overflow: auto;
      margin-bottom: 0px;
      border-radius: 2px;
      > .el-menu-item {
        height: 35px;
        line-height: 35px;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
    }
    .is-disabled {
      .el-input__inner,
      .el-range-input {
        color: $font-color;
      }
    }
    .settings-tab {
      box-sizing: border-box;
      /* height: 100%; */
    }
    .el-input__inner {
      text-align: center;
    }
    .er-tabs {
      .el-tabs__item {
        text-align: center;
        // padding-left: 10px;
        // padding: 0px 0px 0px 0px;
        font-size: 13px;
      }
      .el-tabs__content {
        background-color: #fbfbfb;
        padding: 0px;
      }
    }
    .el-tabs__item {
      padding: 0px 16px;
      height: 40px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 40px;
      display: inline-block;
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      color: #303133;
      position: relative;
    }
    .el-card__body {
      padding: 0px;
    }
    .er-card,
    .el-card {
      border-radius: 7px 7px 14px 14px !important;
    }
  }
  .el-input__suffix {
    text-align: center;
  }
  .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-input__icon {
    line-height: 28px !important;
  }
}
.el-select-dropdown__item.selected {
  font-weight: 700;
  color: #0a2463 !important;
}
.el-select-dropdown {
  // min-width: 120px !important;
  .el-select-dropdown__item {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    height: 28px !important;
    line-height: 28px !important;
  }
}
</style>
