<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondGuardSettings.vue
Description: This file contains UI components of pond guard tab of settings. It parent componet of pond guard settings table
-->
<template>
  <el-row
    class="pg-table"
    v-loading="loading"
    element-loading-background="white"
    justify="center"
  >
    <el-row v-if="!loading && Ponds.length > 0">
      <!-- <el-alert
        ref="error_alert"
        v-if="ehm__unhandledErrorMessage !== ''"
        :title="ehm__unhandledErrorMessage"
        @close="ehm__handleAlertClose"
        type="error"
      ></el-alert> -->

      <el-row>
        <layout-toolbar justify="end" style="margin: 10px 10px">
          <er-input
            inputType="search"
            size="mini"
            v-model="search"
            v-show="!loading"
            suffix-icon="el-icon-search"
            :placeholder="$t('Comn_search')"
             @change="handelSearchChange"
          ></er-input>

          <er-button
            btnType="save"
            size="mini"
            v-show="!loading"
            :showLabel="true"
            :showIcon="true"
            :disabled="isReadOnly"
            :loading="loading"
            @click="submitUpdatedDetails"
          ></er-button>
          <er-button
            v-show="!loading"
            btnType="cancel"
            size="mini"
            :showLabel="true"
            :disabled="isReadOnly"
            @click="initComponent"
          >
          </er-button>
          <er-dropdown
            placement="bottom"
            @item-click="handleTableDataDownload"
            trigger="click"
            class="highlight"
            :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
          >
            <template class="el-dropdown-link download_button" slot="suffix">
              <span class="material-icons-outlined action-icon">
                file_download
              </span>
            </template>
            <template v-slot:item-title="{ item }">{{
              $t(item.value)
            }}</template>
          </er-dropdown>
        </layout-toolbar>
      </el-row>

      <el-row class="pg-settings-table">
        <er-data-tables
          ref="pgsettings"
          uniqueId="pg-settings__table"
          :tableData="dataValues"
          :key="$i18n.locale"
          :total="total"
          v-show="!loading"
          :columns="tableColumns"
          :actionColumn="false"
          :merge="{ startColumnNumber: 1, endColumnNumber: 1 }"
          type="white-header-table"
          :elTableProps="tableProps"
          :sortMethodForColumns="sortMethods()"
          :page-size="$constants.table['page-size']"
          :pagination-props="$constants.table['pagination-props']"
          :searchOnTableData="search"
        >
          <template v-slot:pond_title="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="pond_title"
              :withoutHighlightStrings="row.data.pond_title"
            />
          </template>

          <template v-slot:code="{ row }">
            <er-highlight-search-component
              :highlightStrings="row.data.highlightStrings"
              :individualRowData="row.data"
              keyName="code"
              :withoutHighlightStrings="row.data.code"
            />
          </template>

          <template v-slot:title="{ row }">
            <div class="alias-name-container">
              <el-input
                v-model="row.data.title"
                :placeholder="$t('enter_alias_name')"
                :disabled="isPGExistInSelectedPGs(row.data._id)"
                @change="handlePGChangeInTable($event, row.data)"
                size="mini"
              />
            </div>
          </template>
          <template v-slot:low_do="{ row }">
            <div class="temp-container">
              <el-input-number
                v-model="row.data.do_alerts_config[0].lower_limit"
                :placeholder="$t('Comn_low')"
                :disabled="isPGExistInSelectedPGs(row.data._id) || isReadOnly"
                @change="handlePGChangeInTable($event, row.data)"
                :controls="false"
                :min="0"
                size="mini"
              ></el-input-number>
            </div>
          </template>
          <template v-slot:critical_do="{ row }">
            <div class="do-container">
              <el-input-number
                v-model="row.data.do_alerts_config[0].critical_lower_limit"
                :placeholder="$t('Comn_critical')"
                :disabled="isPGExistInSelectedPGs(row.data._id) || isReadOnly"
                @change="handlePGChangeInTable($event, row.data)"
                :controls="false"
                :min="0"
                size="mini"
              ></el-input-number>
            </div>
          </template>

          <template v-slot:low_temp_header="{ header }">
            <div class="table-col-header-container">
              <el-tooltip
                popperClass="pg-settings-table-el-tooltip__popper"
                placement="top"
                effect="light"
              >
                <div slot="content">
                  {{ $t("pond-guard_temperature_info") }} <br />
                  {{ $t("pond_guard_changes_reflects_info") }}
                </div>
                <p>{{ header.label }}</p>
              </el-tooltip>
            </div>
          </template>
          <template v-slot:high_temp_header="{ header }">
            <div class="table-col-header-container">
              <el-tooltip
                popperClass="pg-settings-table-el-tooltip__popper"
                placement="top"
                effect="light"
              >
                <div slot="content">
                  {{ $t("pond-guard_temperature_info") }} <br />
                  {{ $t("pond_guard_changes_reflects_info") }}
                </div>
                <p>{{ header.label }}</p>
              </el-tooltip>
            </div>
          </template>

          <template v-slot:low_temp="{ row }">
            <el-row type="flex" :gutter="10" class="temp-container">
              <el-input-number
                v-model="row.data.temperature_alerts_config[0].lower_limit"
                :disabled="isPGExistInSelectedPGs(row.data._id) || isReadOnly"
                :placeholder="$t('Comn_min')"
                @change="handlePGChangeInTable($event, row.data)"
                size="mini"
                :precision="1"
                :min="10"
                :max="60"
                :controls="false"
              ></el-input-number>
              <!-- <el-input-number
                v-model="row.data.temperature_alerts_config[0].upper_limit"
                :disabled="isPGExistInSelectedPGs(row.data._id) || isReadOnly"
                :placeholder="$t('Comn_max')"
                @change="handlePGChangeInTable($event, row.data)"
                size="mini"
                :controls="false"
                :min="0"
                :max="50"
              ></el-input-number> -->
            </el-row>
          </template>
          <template v-slot:high_temp="{ row }">
            <el-row type="flex" :gutter="10" class="temp-container">
              <!-- <el-input-number
                v-model="row.data.temperature_alerts_config[0].lower_limit"
                :disabled="isPGExistInSelectedPGs(row.data._id) || isReadOnly"
                :placeholder="$t('Comn_min')"
                @change="handlePGChangeInTable($event, row.data)"
                size="mini"
                :min="0"
                :max="50"
                :controls="false"
              ></el-input-number> -->
              <el-input-number
                v-model="row.data.temperature_alerts_config[0].upper_limit"
                :disabled="isPGExistInSelectedPGs(row.data._id) || isReadOnly"
                :placeholder="$t('Comn_max')"
                @change="handlePGChangeInTable($event, row.data)"
                size="mini"
                :precision="1"
                :controls="false"
                :min="10"
                :max="60"
              ></el-input-number>
            </el-row>
          </template>
        </er-data-tables>
      </el-row>
    </el-row>
    <Loader v-else-if="loading"></Loader>
    <er-no-ponds :tabData="tabData" :disabled="isReadOnly" v-else />
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import PondGuard from "@/model/pondguard";
import RuleOption from "@/model/ruleOption";
import Loader from "@/components/base/Loader";
import exportFileDownload from "@/mixins/exportFileDownload";
export default {
  mixins: [errorHandlerMixin, exportFileDownload],
  components: {
    Loader
  },
  props: {
    tabData: {
      default: {}
    }
  },
  data: function() {
    return {
      data: {},
      total: 0,
      search: "",
      isPondUnAssign: false,
      pondGuardObj: new PondGuard(),
      pondsSelected: [],
      ehm__errMessagesObject: new PondGuard(),
      selectedPGIds: [],
      editPGObj: {},
      tableEditPGObj: {},
      loading: false,
      tableProps: {
        // border: true,
        // stripe: true,
        rowKey: this.getRowKey,
        size: "mini",
        defaultSort: {
          prop: "pond_title",
          order: "ascending"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      query: {
        get_all: true,
        order_by: null,
        order_type: null,
        un_assigned_only: false,
        location_id: null
      }
    };
  },
  computed: {
    ...mapGetters({
      PondGuards: "pondguard/getPondGuards",
      mapPondIdPond: "pond/getMapPondidPond",
      mapStIdSt: "shrimptalk/getMapStidSt",
      Ponds: "pond/getPonds",
      getPgIdPGObj: "pondguard/getPgIdPGObj"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      isReadOnly: "isReadOnly",
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pond", {
      Ponds: "getPonds"
    }),
    selectedPondPGs: function() {
      return this.pondsSelected
        .map((pond_id) => this.mapOfPondToPgs[pond_id].pond_guards)
        .flat(1);
    },
    selectedPondPGIds: function() {
      return this.selectedPondPGs.map((pg) => pg._id);
    },
    mapOfPondToPgs: function() {
      return this.dataValues.reduce((acc, pg) => {
        if (!acc[pg.pond_id]) {
          acc[pg.pond_id] = {
            title: pg.pond_title,
            _id: pg.pond_id,
            pond_guards: []
          };
        }
        acc[pg.pond_id].pond_guards.push(pg);
        return acc;
      }, {});
    },
    pondModesOfOperations() {
      return {
        basic: "PG_sch_mode4",
        schedule: "PG_schedule",
        automatic: "PG_automatic"
      };
    },
    tableColumns() {
      return {
        pond_title: {
          prop: "pond_title",
          label: this.$t("Comn_pond_name"),
          minWidth: 85,
          sortable: "custom",
          searchProperty: "pond_title",
          enableSearch: true
        },
        code: {
          prop: "code",
          label: this.$t("Comn_pond_guard_ID"),
          minWidth: 85,
          searchProperty: "code",
          enableSearch: true
        },
        low_do: {
          prop: "do_alerts_config.lower_limit",
          label: `${this.$t("Comn_low_do")}`,
          minWidth: 85,
          enableSearch: true,
          searchProperty: "do_alerts_config.lower_limit"
        },
        critical_do: {
          prop: "do_alerts_config.critical_lower_limit",
          label: `${this.$t("Comn_do")} ${this.$t("Comn_critical")}`,
          minWidth: 80,
          enableSearch: true,
          searchProperty: "do_alerts_config.critical_lower_limit"
        },
        // temp_range: {
        //   prop: "temp_range",
        //   label: `Temp ${this.$t(
        //     "Comn_range"
        //   )} (\u00b0${this.getPreferredUnits.temperature_units.toUpperCase()})`,
        //   minWidth: 120
        // },
        low_temp: {
          prop: "low_temp",
          label: `${this.$t(
            "Comn_low"
          )} Temp (\u00b0${this.getPreferredUnits.temperature_units.toUpperCase()})`,
          minWidth: 80
        },
        high_temp: {
          prop: "high_temp",
          label: `${this.$t(
            "Comn_high"
          )} Temp (\u00b0${this.getPreferredUnits.temperature_units.toUpperCase()})`,
          minWidth: 80
        },
        title: {
          prop: "title",
          label: this.$t("Comn_alias_name"),
          minWidth: 100,
          enableSearch: true,
          searchProperty: "title"
        }
      };
    },
    downloadColumns() {
      return [
        {
          header: this.tableColumns.pond_title.label,
          dataKey: "pond_title",
          emptyValue: "--",
          name: "title",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.code.label,
          dataKey: "code",
          emptyValue: "--",
          name: "code",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.low_do.label,
          dataKey: ["do_alerts_config", "0", "lower_limit"],
          emptyValue: "--",
          name: "lower_limit",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },

        {
          header: this.tableColumns.critical_do.label,
          dataKey: ["do_alerts_config", "0", "critical_lower_limit"],
          emptyValue: "--",
          name: "critical_lower_limit",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },

        {
          header: this.tableColumns.low_temp.label,
          dataKey: (value) => {
            return `${value.temperature_alerts_config[0].lower_limit}`;
          },
          name: "low_temp",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.high_temp.label,
          dataKey: (value) => {
            return `${value.temperature_alerts_config[0].upper_limit}`;
          },
          name: "high_temp",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        },
        {
          header: this.tableColumns.title.label,
          dataKey: "title",
          name: "alias_name",
          emptyValue: "--",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 15
        }
      ];
    },
    dataValues() {
      return Object.values(this.data).map((x) => {
        x.pond_title = x.pond.title;
        return x;
      });
    },
    // isTableEditted() {
    //   return Object.keys(this.tableEditPGObj).length > 0;
    // },
    location() {
      return this.$store.getters["user/getCurrUserLocation"];
    },
    validationRules() {
      const deviceLangKey = "Comn_pond_guard";
      const fieldLangKey = {
        low_do: "Comn_low_do",
        critical_do: "Comn_critical_do",
        temp_min: "Comn_temp_min",
        temp_max: "Comn_temp_max"
      };

      const rulesOptionsFunction = (
        fieldLangKey,
        itemTitle,
        minValue,
        maxValue,
        otherFieldValue,
        otherFieldLangKey,
        otherFieldValidationType
      ) => {
        return [
          RuleOption.createBasicRule(
            "REQUIRED",
            fieldLangKey,
            deviceLangKey,
            itemTitle
          ),
          RuleOption.createRuleToCompareWithOtherField(
            otherFieldValidationType,
            fieldLangKey,
            otherFieldLangKey,
            deviceLangKey,
            itemTitle,
            otherFieldValue
          )
        ];
      };
      return (payloadItem) => {
        const itemTitle = payloadItem.title;
        const itemCode = payloadItem.code;
        const otherFieldValue = (path) => {
          return this.$lodash.get(payloadItem, path);
        };
        return [
          {
            field: "do_alerts_config[0].lower_limit",
            rules: rulesOptionsFunction(
              fieldLangKey.low_do,
              itemTitle || itemCode,
              1,
              50,
              otherFieldValue("do_alerts_config[0].critical_lower_limit"),
              fieldLangKey.critical_do,
              "GREATER_THAN_EQUAL_OTHER_FIELD"
            )
          },
          {
            field: "do_alerts_config[0].critical_lower_limit",
            rules: rulesOptionsFunction(
              fieldLangKey.critical_do,
              itemTitle || itemCode,
              1,
              50,
              otherFieldValue("do_alerts_config[0].lower_limit"),
              fieldLangKey.low_do,
              "LESS_THAN_EQUAL_OTHER_FIELD"
            )
          },
          {
            field: "temperature_alerts_config[0].lower_limit",
            rules: rulesOptionsFunction(
              fieldLangKey.temp_min,
              itemTitle || itemCode,
              20,
              100,
              otherFieldValue("temperature_alerts_config[0].upper_limit"),
              fieldLangKey.temp_max,
              "LESS_THAN_OTHER_FIELD"
            )
          },
          {
            field: "temperature_alerts_config[0].upper_limit",
            rules: rulesOptionsFunction(
              fieldLangKey.temp_max,
              itemTitle || itemCode,
              20,
              100,
              otherFieldValue("temperature_alerts_config[0].lower_limit"),
              fieldLangKey.temp_min,
              "GREATER_THAN_OTHER_FIELD"
            )
          }
        ];
      };
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions({
      fetchAllPondGuards: "pondguard/fetchAllPondGuards",
      fetchAllPonds: "pond/fetchAllPonds",
      updateArrPondGuardDetails: "pondguard/updateArrPondGuardDetails"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.search !== '') {
        this.mixPanelEventGenerator({ eventName: "Settings - PondGuard - Search" });
      }
    },
    async initComponent() {
      this.pondsSelected = [];
      this.selectedPGIds = [];
      this.pondGuardObj = new PondGuard();
      this.tableEditPGObj = {};
      this.loading = true;
      try {
        await this.fetchAllPonds({
          location_id: this.getCurrUserLocation._id,
          get_all: true,
          status: ["ACTIVE", "INACTIVE"]
        });
        await this.loadPGList();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.pgsettings) {
            this.$refs.pgsettings.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    handleTableDataDownload(downloadtype) {
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
      this.mixPanelEventGenerator({ eventName: "Settings - PondGuard - Download" });
    },
    xceldownload() {
      this.exp__Download(
        this.$refs.pgsettings.$refs.dataTables.sortedData,
        this.downloadColumns,
        "xcel"
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.pgsettings.$refs.dataTables.sortedData,
        this.downloadColumns,
        "pdf"
      );
    },
    getRowKey(row) {
      return row.pond_mother_id;
    },
    sortMethods() {
      return {
        pond_title: this.$commonUtils.alphaNumericComparator
      };
    },
    getPond(pond_id) {
      if (this.mapPondIdPond && this.mapPondIdPond.get(pond_id)) {
        return this.mapPondIdPond.get(pond_id);
      } else {
        return { pond_guards: [] };
      }
    },

    handlePGChangeInTable(event, pg) {
      pg.title = pg.title.trim();
      this.$set(this.tableEditPGObj, pg._id, pg);
    },
    isPGExistInSelectedPGs(pgId) {
      return this.selectedPGIds.indexOf(pgId) > -1;
    },
    async submitUpdatedDetails() {
      this.loading = true;
      const payLoadObj = {};
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        Object.values(this.tableEditPGObj).forEach((pg) => {
          if (!payLoadObj[pg._id]) {
            payLoadObj[pg._id] = pg;
          }
        });
        const payload = Object.values(payLoadObj);

        if (this.PondGuards.length === 0) {
          return;
        }
        if (payload.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_no_changes_found", {
                  item: this.$t("Comn_pond_guard")
                })
              }
            ]
          };
        }
        const errors = payload.reduce((acc, payloadItem) => {
          acc.push(
            ...this.ehm__ruleValidator(
              payloadItem,
              this.validationRules(payloadItem),
              "REQUIRED"
            )
          );
          return acc;
        }, []);
        if (errors.length > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors
          };
        }
        this.loading = true;
        this.ehm__errorMessagesObject = new PondGuard();
        const arrPromises = [];
        payload.forEach((record) => {
          arrPromises.push();
        });
        await this.updateArrPondGuardDetails(payload);
        this.ehm__errorMessagesObject = new PondGuard();
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("pond_guard_details_updated_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Settings - PondGuard - Save" });
        await this.initComponent();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async loadPGList() {
      this.loading = true;
      try {
        this.query.location_id = this.location._id;
        const response = await this.fetchAllPondGuards({
          location_id: this.location._id,
          get_all: true,
          include_pond_details: true
        });
        this.PondGuards.forEach((pg) => {
          this.$set(this.data, pg._id, this.$lodash.cloneDeep(pg));
        });
        this.total = response.totalPGs;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.pg-dropdown {
  .el-select-dropdown__item {
    // font-size: 11px;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    height: 20px;
    display: flex;
    align-items: center;
  }
}
.pg-settings-table {
  @include responsiveProperty(--table-gutter, 213px, 212px, 220px);
  .er-data-tables .white-header-table .el-table td .cell,
  .er-data-tables .white-header-table .el-table th .cell {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    text-align: left;
    justify-items: flex-start;
    align-items: flex-start;
  }
  .el-input {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-input__inner {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}
.pg-table {
  $input_width: 10rem;

  .el-tag {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2,
      true
    );
  }
  .toolbar-filters {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    // background: white;
    .toolbar-layout {
      padding: 10px;
    }
  }

  .pg-settings-table {
    padding: 0 12px;
    .alias-name-container {
      .el-input--mini {
        width: 120px;
      }
    }
  }
  .toolbar-filters > * {
    // margin-right: 10px;
    padding: 10px;

    .ponds-container {
      width: 120px;
    }
    .pg-id-container {
      width: 120px;
    }
  }
  .el-button + .el-button {
    margin-left: 0px;
  }
  .table {
    margin: 10px;
  }
  .el-table {
    .el-input-number--small {
      width: 100%;
    }
  }

  .do-container {
    .el-input--mini {
      width: 70px;
    }
    .el-input-number--small {
      width: 70px;
      line-height: 30px;
    }
  }
  .temp-container {
    .el-input--mini {
      width: 70px;
    }
    .el-input-number--small {
      width: 70px;
      line-height: 30px;
    }
  }

  .el-table--scrollable-x .el-table__body-wrapper {
    // overflow: hidden;
  }
  .el-pagination__sizes {
    .el-input--mini .el-input__inner {
      height: 22px;
      line-height: 22px;
    }
  }
  .el-pagination__total {
    margin-left: 20px;
  }
  .pg-tag-list {
    display: flex;
    flex-direction: row;
    margin: 10px;
    box-sizing: border-box;
  }
  .er-data-tables .pagination-bar {
    margin-bottom: 10px;
  }

  .el-input-number--mini {
    width: 100px !important;
    line-height: 26px;
  }
}
.pg-settings-table-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}

.pg-settings-table-el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #606266 !important;
  bottom: -7px;
}
</style>
