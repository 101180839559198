<script>
import filtersMixin from "@/mixins/filtersMixin";
import User from "@/model/user";
import { mapGetters } from "vuex";
import {
  userTypes,
  FTPermissionsToActions
} from "@/middleware/pageAccessManager.js";
import pageAccessComponent from "./pageAccessComponent";
export default {
  name: "UserDetails",
  mixins: [filtersMixin],
  components: { pageAccessComponent },
  props: {
    type: {
      default: () => {
        "details";
      }
    },
    loading: {
      default: true
    }
  },
  data: function () {
    return {
      types: {
        details: "details",
        userAccessMgmtUpdatePassword: "userAccessMgmtUpdatePassword",
        userAccessMgmtEdit: "userAccessMgmtEdit",
        userAccessMgmtCreate: "userAccessMgmtCreate"
      },
      userFields: {
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        phone: "Comn_mobile_num",
        email: "Comn_email",
        country: "Comn_country",
        timezone: "Comn_timezone"
      },
      checkedPermissionsNew: FTPermissionsToActions,
      isIndeterminateLocations: true,
      checkAllLocations: false,
      checkedLocations: []
    };
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getSidePanelState: "getSidePanelState"
    }),
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    checkedPermissions() {
      return this.getUserData.parsedPermissions;
    },
    checkedUserLocations() {
      return this.getUserData.allowed_locations;
    },
    locations() {
      return this.$store.getters["user/getUserLocations"];
    },
    locationIds() {
      return this.locations.map((location) => location._id);
    },
    getNewUser() {
      return new User();
    },
    enableLocations: function () {
      return (
        ["POND_WORKER", "POND_TECHNICIAN", "POND_MANAGER", ""].indexOf(
          this.getUserData.user_type
        ) > -1
      );
    }
  },
  render(h) {
    return (
      <div class="user-information-section right-panel">
        <layout-toolbar
          justify="start"
          class="user-information-section__header"
        >
          <span class="material-icons-outlined">info</span>
          <p class="user-information-section__header-title">
            {this.ftm__capitalize(this.$t("Usrs_necessary_info_update"))}
          </p>
        </layout-toolbar>
        <el-row class="user-information-section__content right-panel-content-alignment">
          {Object.keys(this.userFields).map((key) => {
            return (
              <div class="user-information-section__content-row">
                <p class="user-information-section__content-column">
                  {this.$t(this.userFields[key])}
                </p>

                {this.getUserFieldView(h, key, this.getUserData)}
              </div>
            );
          })}
        </el-row>
        {this.getPageAccessManagementComponent(h)}
      </div>
    );
  },
  methods: {
    getPageAccessManagementComponent(h) {
      const layout = this.getUserTypeToPageAccessLayout(
        this.getUserData.user_type
      );
      if (layout.length && this.getUserData.user_type !== 'ACCOUNT_MANAGER_SUB_USER' && this.getUserData.user_type !== 'ACCOUNT_MANAGER') {
        return (
          <pageAccessComponent
            layout={layout}
            locations-list={this.locations}
            permissions-list={this.$gblUAMPermissionsToPermissionDetailsList}
            user-details={this.getUserData}
            editable={false}
          ></pageAccessComponent>
        );
      }
    },
    getUserTypeToPageAccessLayout(userType) {
      switch (userType) {
        case userTypes.pond_owner:
        case userTypes.admin:
        case userTypes.no_access:
          return [];
        case userTypes.pond_manager:
          return ["locations"];
        default:
          return ["permissions", "locations"];
      }
    },
    getUserFieldView(h, field, fieldModel) {
      switch (field) {
        case "country":
        case "timezone":
          return (
            <p
              class="user-information-section__content-column"
              style="color: #1f2041"
            >
              {this.ftm__capitalize(fieldModel[field].name)}
            </p>
          );
        default:
          return (
            <p class="user-information-section__content-column" style="">
              {fieldModel[field]}
            </p>
          );
      }
    }
  }
};
</script>

<style lang="scss">
.right-panel {
  padding-bottom: 20px;
  .user-information-section {
    &__header {
      font-weight: 700;
      @include normal-text;
      color: $primary-color;
      &:first-child {
        padding: 25px 15px 0px;
      }
    }
    &__content {
      $parent-scope: &;
      &-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px 0;
      }
      &-column {
        color: #1f2041;
        @include small-text;
        &:first-child {
          width: 200px;
          color: #6c7b8a;
        }
      }
    }
  }
}
</style>
